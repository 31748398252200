import i18n from "i18next";                      
import { initReactI18next } from "react-i18next";


const userLocale = navigator.language;

i18n
  .use(initReactI18next)
  .init({
    // Config options

    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We use English here, but feel free to use
    // whichever locale you want.                   
    lng: `${userLocale}`,

    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here. 
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },

    // Translation messages. Add any languages
    // you want here.
    resources: {
      en: {
        translation: {
            car: "car",
            Contact_us: "Contact us",
            Mail: "Mail",
            Telephone: "Telephone",
            header: "header",
            About_us: "Offer",
            Sightseeing: "Sightseeing",
            Furnishings: "Furnishings and amenities",
            Back: "Back",
            Shared_Swimming_pool: "Shared swimming pool",
            Free_Parking: "Free parking",
            No_Smoking_Allowed: "No smoking allowed",
            No_Pets_Allowed: "No pets allowed",
            Washing_Machine_And_Dryer: "Washing machine and dryer",
            Soap_Shampoo: "Soap, shampoo",
            Towels_Linen_Toilet_Paper: "Towels, linen, toilet paper",
            Wardrobe_With_Hangers: "Wardrobe with hangers",
            Iron: "Iron",
            Tv_With_Smart_Tv: "Tv with Smart TV",
            Wi_Fi: "Wi-Fi",
            Crib_Chair_Toys_For_The_Little_Ones: "Crib, chair, toys for the little ones",
            Microwave_Kettle_Cooktop_Toaster: "Microwave, kettle, cooktop, toaster",
            Crockery_And_Cutlery: "Crockery and cutlery",
            Refrigerator_And_Freezer: "Refrigerator and freezer",
            Coffee_Machine: "Coffee machine",
            Terrace_Or_Balcony_Furniture: "Terrace or balcony, furniture",
            Garden_View: "Garden view",
            About_Costa_Calma: "About Costa Calma",
            Apartment_Info1: "Welcome to <strong>{{island.props.children}}</strong> – an island that captivates tourists with its rugged beauty, golden beaches, and crystal-clear ocean waters. If you dream of the perfect vacation filled with sunshine, relaxation, and unforgettable views, our apartments <strong>{{colores23.props.children}}</strong> and <strong>{{colores27.props.children}}</strong> in Costa Calma are waiting for you.",
            Apartment_Info2: "We offer 2 fully furnished apartments with a terrace, each accommodating 4 people.",
            Apartment_Info3: "The apartments are located in a gated community with access to a swimming pool and a shop located 20 meters from the entrance.",
            Apartment_1_description: "Are you looking for the perfect place for a <strong>holiday in Fuerteventura?</strong> The apartment <strong>Colores de Fuerteventura 23</strong> in the town of <strong>Costa Calma</strong> is a modern, cozy interior that offers exceptional comfort and relaxation. After a thorough renovation in 2023, the apartment provides a <strong>high standard</strong> of finishing, designed with discerning guests in mind. It is located in a picturesque complex with a pool and palm trees.",
            Apartment_1_description_2: "The Colores de Fuerteventura 23 apartment, located on the ground floor with a <strong>terrace shaded by exotic plants</strong>, guarantees a peaceful stay in an intimate atmosphere. Guests can enjoy the charming boho-style interior in the island's <strong>pastel colors</strong>. It was designed by a designer and has gained a unique holiday, island character.",
            Apartment_1_description_3: "The Colores de Fuerteventura 23 apartment is an excellent base for exploring the beauty of the island. Located near <strong>Sotavento Beach</strong>, just 700 meters from the apartment, it offers guests quick access to one of the island's most beautiful beaches. Nearby, there is also a shopping center, restaurants, bars, and a local market where you can experience the exotic atmosphere of Fuerteventura.",
            Apartment_header_text: "Comfortable apartment after renovation – Your private paradise in Fuerteventura",
            Apartment_header_text_2: "Perfect location – close to the beach and local attractions",
            Apartment_reservation_header: "Book your stay today!",
            Apartment_1_reservation_1: "If you are looking for peace and proximity to nature, the Colores de Fuerteventura 23 apartment is the perfect choice for your holiday.",
            Apartment_1_reservation_2: "Fuerteventura is waiting for you – immerse yourself in its unique atmosphere and enjoy an unforgettable holiday on one of the most beautiful Canary Islands.",
            Apartment_2_description: "Are you looking for the perfect place for a <strong>holiday in Fuerteventura?</strong> The apartment <strong>Colores de Fuerteventura 27</strong> in the town of <strong>Costa Calma</strong> is a modern, cozy interior that offers exceptional comfort and relaxation. After a thorough renovation in 2023, the apartment provides a <strong>high standard</strong> of finishing, designed with discerning guests in mind. It is located in a picturesque complex with a pool and palm trees.",
            Apartment_2_description_2: "The Colores de Fuerteventura 27 apartment, located on the first floor with a <strong>terrace shaded by exotic plants</strong>, guarantees a peaceful stay in an intimate atmosphere. Guests can enjoy the charming boho-style interior in the island's <strong>pastel colors</strong>. It was designed by a designer and has gained a unique holiday, island character.",
            Apartment_2_description_3: "The Colores de Fuerteventura 27 apartment is an excellent base for exploring the beauty of the island. Located near <strong>Sotavento Beach</strong>, just 700 meters from the apartment, it offers guests quick access to one of the island's most beautiful beaches. Nearby, there is also a shopping center, restaurants, bars, and a local market where you can experience the exotic atmosphere of Fuerteventura.",
            Apartment_2_reservation_1: "If you are looking for peace and proximity to nature, the Colores de Fuerteventura 27 apartment is the perfect choice for your holiday.",
            Apartment_2_reservation_2: "Fuerteventura is waiting for you – immerse yourself in its unique atmosphere and enjoy an unforgettable holiday on one of the most beautiful Canary Islands.",
            Costa_Calma_Info: "A small and quiet town on the southwestern coast of the island, with plenty of greenery and palm trees. A beautiful palm-lined avenue runs through the middle of the town, making it an excellent spot for walks and jogging at any time of day and in any weather. Costa Calma is an ideal place for those seeking peace and quiet, especially families with children due to its beaches, calm waters and waves (swimming areas with lifeguards), and water sports enthusiasts. The town has 3 supermarkets, numerous shops and boutiques, a gas station, post office, pharmacies, and emergency medical care. If you don't want to cook, there are many affordable bars and restaurants on site. Costa Calma is a very good base for exploring the island, but a car is necessary – there are several car rental agencies available locally.",
            Popcorn_beach: "Popcorn beach – a unique place with white pieces of coral that look like popcorn.",
            Calderon_Hondo: "Calderon Hondo Volcano – one of the youngest volcanoes on the island. It is located near Lajeres. The ascent is not burdensome or difficult, as the path to the volcano's edge goes around the crater. At the top of the volcano (278 meters above sea level), there is a viewing platform. The entire round trip takes about 2 hours.",
            Mirador_de_Guise_y_Ayose: "Mirador de Guise y Ayose and Mirador Morro Velosa – two attractive viewpoints with beautiful views of the Betancuria valley and Fuerteventura – great photos guaranteed. At Mirador de Guise y Ayose, there are two large statues of the last Guanche rulers (15th century). Numerous squirrels, characteristic of this region.",
            Antigua: "A quiet and beautiful town in the middle of Fuerteventura with the interesting church of Nuestra Senora de Antigua located in a small square in the town center. Another important attraction is the cheese production museum – Museo del Queso Majorero. Near the parking area, you can visit a beautiful large windmill and a garden with a variety of cacti. Inside, you can learn about the conditions, production processes, and types of goat cheeses in Fuerteventura.",
            Betancuria: "Betancuria – the former capital of Fuerteventura, now a small tourist town worth visiting for the beautiful Iglesia Catedral de Santa Maria de Betancuria and numerous bars and cafes.",
            Arco_de_las_planitas: "Another recommended hiking trail is Arco de las Penitas. You can drive to the base of the hill and then take a 2-hour route to see limestone rock formations, a beautiful gorge, and an old, inactive dam.",
            Ajuy: "A tiny village with a beautiful bay featuring black sand. There are 3 very good seafood restaurants (dinner for 4 with a bottle of wine costs around €100). A path from the beach leads to the Pirate Caves, Cuevas de Ajuy (about 20 minutes) – a great viewpoint.",
            Aguas_Verdes: "Aguas Verdes is a rocky coastline with numerous cliffs and natural pools – perfect for swimming.",
            Caleta_de_Fuste: "Caleta de Fuste – a tourist town with a beautiful beach, marina, and many bars and restaurants. Nearby are several golf courses. About 2 km south is the Salt Museum – you can walk there from the town. Another attraction in the area is the so-called Krabbenfelsen – a natural pool formed during low tide.",
            Oasis_Park: "For families with children and others, a major attraction is the Oasis Park, a zoological-botanical garden located about 10 km away – accessible by car, taxi, or bus from the roundabout at the town entrance. Visiting takes 3-5 hours, but there are many shaded areas. A big attraction for children is feeding giraffes, camels, and other animals. Additionally, there is an extensive botanical garden with various cactus species.",
            Tarajalejo: "Tarajalejo is a small town just a few kilometers from Costa Calma, situated by a picturesque bay. A one-hour walk along the ocean's shore to the east will take you to a beautiful cave. In the eastern part of the town, right on the shore, we recommend the local restaurant La Barracca, offering a variety of fish and seafood dishes and excellent house wine.",
            La_Pared: "La Pared – a village on the northern coast, where the main attractions are the sunsets and ideal conditions for surfing. In Costa Calma, there are several places with instructors who provide transport and equipment. Note – the northern beaches are less accessible and quite dangerous due to the waves and underwater currents. On-site, we recommend the excellent Caretta Beach La Pared restaurant – great food and wine, and for dessert, stunning ocean views. The village has a typical surfer atmosphere, with two bars offering great drinks – live music on Fridays.",
            Playa_de_Sotavento: "Playa de Sotavento – the most famous and renowned beach on the island and beyond. It's a stunning sandy beach with crystal-clear turquoise water and gentle ocean waves. Due to the beach's location and shape, a beautiful lagoon forms during low tide. Sotavento Beach starts from Costa Calma and stretches over 20 km to Morro Jable. At the southern end of the beach in Costa Calma, towards the southwest, we recommend the fantastic Plamita Pepe y Antonia bar – serving amazing fish and seafood right by the ocean.",
            Pico_de_Zarza: "Pico de Zarza is the highest peak on Fuerteventura, standing at 807 meters above sea level. It's one of the island's more attractive hiking trips. From the parking lot at the base of the mountain, it takes about 2 hours of easy hiking to reach the summit (about 1 hour to descend). Along the way, you'll encounter numerous squirrels and goats. Be sure to bring water and a jacket, especially in windy weather. The summit offers a magnificent view of the western tip of the island, including Morro Jable and Cofete Beach, along with the Winter Villa.",
            Cofete: "Cofete – a beautiful, remote beach accessible from Morro Jable by car via a gravel, unpaved road (note – rental car insurance typically does not cover this type of trip). Along the way, you have the chance to visit the Winter Villa, but an appointment is necessary. It's worth taking a short detour towards the westernmost point of the island to see the Punta de Jandia lighthouse.",            
            Airbnb_reservation: "Book on Airbnb",
            Host: "The administrator of the apartments is Robert Superhost Airbnb",
            Fuerteventura_description: "<strong>{{island.props.children}}</strong> as one of the oldest Canary Islands offers plenty of attractions.<br /><br />Visit <strong>{{park.props.children}}</strong>, where you can admire vast dunes and picturesque landscapes, or <strong>{{oasis.props.children}}</strong> – a unique zoo and botanical garden. Don't miss <strong>{{cotillo.props.children}}</strong> – a charming fishing village with beautiful beaches and an 18th-century fortress. Discover the amazing beaches of <strong>{{sotavento.props.children}}</strong> and <strong>{{cofete.props.children}}</strong>, where nature amazes with its wild beauty. Visit <strong>{{ajuy.props.children}}</strong> – prehistoric caves, one of the oldest parts of the island. <strong>{{island.props.children}}</strong> is also a paradise for water sports enthusiasts – from surfing to diving.",
            Climate: "Climate",
            Fuerteventura: "Fuerteventura",
            Place_select: "Select a place on the map to learn more.",
            Privacy_respect: "We respect your privacy.",
            Cookies_consent: "We would like to use cookies to provide you with a convenient experience on the <strong>{{webpage.props.children}}</strong> portal and access to the latest improvements we create through ongoing analytics. If you agree to the use of all cookies exclusively for statistical purposes, please click 'Accept.' You can always withdraw your consent by changing your cookie settings, deleting them, or adjusting your browser settings.",
            Accept: "Accept",
            Close: "Close",
            Climate_title: "The Climate of the Paradise Island of Fuerteventura",
            Climate_description_1: "Fuerteventura, one of the oldest islands in the <strong>Canary Islands</strong> archipelago, is renowned for its unique climate and spectacular landscapes. Thanks to its location in the Atlantic Ocean, just 100 kilometers from the African coast, the island enjoys a mild semi-desert climate with a subtropical character. It is a place where natural diversity meets exceptional weather conditions, attracting tourists from around the world all year round.",
            Climate_description_2: "On <strong>Fuerteventura</strong>, there are practically no large temperature fluctuations, with average values ranging from 18°C in winter to 30°C in summer. However, the island is known for its trade winds, which bring the cooling influence of the ocean and help keep the climate pleasantly mild, even during the summer months. These winds, regularly blowing from the northeast, have earned <strong>Fuerteventura</strong> the nickname 'Island of Wind.' They are especially favorable for water sports enthusiasts, such as windsurfing and kitesurfing, making Fuerteventura one of the best locations for these activities.",
            Climate_description_3: "Below is a detailed description of the climate and weather conditions in the town of <strong>Costa Calma</strong>, divided by seasons, which will help in planning your stay according to your weather preferences and activities.",
            Spring: "Spring (March - May)",
            Summer: "Summer (June - August)",
            Autumn: "Autumn (September - November)",
            Winter: "Winter (December - February)",
            Spring_text_1: "<strong>Temperature:</strong> In spring, temperatures range from 20°C to 25°C during the day, while nights are slightly cooler, reaching around 15°C - 18°C.",
            Spring_text_2: "<strong>Rainfall:</strong> Rainfall is very rare in spring, which keeps the landscape dry and semi-desert. Occasional light showers occur at the beginning of spring.",
            Spring_text_3: "<strong>Wind:</strong> Trade winds begin to intensify during this period, especially in May, making the island a paradise for surfers, windsurfers, and kitesurfers.",
            Spring_text_4: "<strong>Humidity:</strong> Humidity is relatively low, contributing to comfort even at higher temperatures.",
            Spring_text_5: "<strong>Sea:</strong> The water temperature in spring hovers around 18-20°C, which may be a bit cool for some, though still swimmable.",
            Summer_text_1: "<strong>Temperature:</strong> In summer, temperatures range between 25°C and 30°C, although they can exceed 30°C, especially during the influx of hot air from the Sahara (the calima phenomenon). Nighttime temperatures are pleasant, ranging from 20°C to 24°C.",
            Summer_text_2: "<strong>Rainfall:</strong> Summer in Fuerteventura is practically rainless, and precipitation during this period is a true rarity.",
            Summer_text_3: "<strong>Wind:</strong> Trade winds blow the strongest in summer, cooling the island and making the heat much more bearable.",
            Summer_text_4: "<strong>Humidity:</strong> In summer, humidity is low, which reduces the feeling of stuffiness despite the high temperatures.",
            Summer_text_5: "<strong>Sea:</strong> The ocean temperature rises to 22-23°C, which is ideal for swimming and water sports.",
            Autumn_text_1: "<strong>Temperature:</strong> In autumn, temperatures are very mild, with daytime values ranging from 24°C to 28°C and nighttime temperatures between 19°C and 22°C.",
            Autumn_text_2: "<strong>Rainfall:</strong> Autumn is the only period when more noticeable rainfall may occur, although it is still rare and does not last long.",
            Autumn_text_3: "<strong>Wind:</strong> Autumn winds weaken compared to summer, contributing to somewhat calmer conditions, ideal for tourists who prefer less windy weather.",
            Autumn_text_4: "<strong>Humidity:</strong> There is a noticeable increase in humidity, but it remains moderate.",
            Autumn_text_5: "<strong>Sea:</strong> The water temperature in autumn is a pleasant 22-24°C, attracting water sports enthusiasts and bathers.",
            Winter_text_1: "<strong>Temperature:</strong> Winter in Fuerteventura is exceptionally mild – daytime temperatures reach 19°C to 22°C, while nighttime temperatures drop to 15°C to 18°C.",
            Winter_text_2: "<strong>Rainfall:</strong> December and January are months when there is a bit more rain, although it is still a small amount.",
            Winter_text_3: "<strong>Wind:</strong> In winter, the wind is significantly weaker than in summer, making the island calmer and more suitable for tourists preferring milder weather conditions.",
            Winter_text_4: "<strong>Humidity:</strong> Humidity increases to an average level, making the climate slightly more humid than in summer.",
            Winter_text_5: "<strong>Sea:</strong> The ocean temperature drops to around 18-20°C, but it is still possible to engage in water sports and swimming, although it may be cool for some.",
            Commuting_info: "On this page, you will find detailed information about available transportation options to reach our apartments from <strong>Puerto del Rosario</strong> airport. Below is also an airport map to assist with navigation and planning your journey.",
            Commuting_tiadhe_1: "You can reach the apartments using Tiadhe city buses, <strong>line 10</strong>. Tiadhe buses are marked in <strong>(white-turquoise or navy blue)</strong>. The bus stop is located in front of the main entrance. The schedule will be posted on a metal pole.",
            Commuting_tiadhe_2: "You can find a link to the Tiadhe website and the schedule for line 10 ",
            Commuting_tiadhe_3: "here",
            Commuting_tiadhe_4: "The ticket cost will be around",
            Commuting_tiadhe_5: "10 € / person",
            Commuting_shuttle_1: "After exiting the main airport corridor, head right along the road, continue straight past the taxi stand. The shuttle bus stop will be in front of you - look for <strong>orange coaches</strong>.",
            Commuting_shuttle_2: "On the carrier’s website",
            Commuting_shuttle_3: "just select the apartment address, flight number, and scheduled arrival time. Then, up to 72 hours before arrival, you’ll receive an email with the shuttle’s departure time. <strong>This bus waits for its passengers</strong>.",
            Commuting_shuttle_4: "The ticket cost will be around",
            Commuting_shuttle_5: "17 € / person",
            Commuting_taxi_1: "After exiting the main airport corridor, head right along the road, the taxi stand is marked with a Taxi sign. Only licensed <strong>white taxis</strong> are available here. Unfortunately, Uber/Bolt apps are not available at the airport.",
            Commuting_taxi_2: "The fare depends on the taximeter.",
            Commuting_taxi_3: "The cost of the trip will be approximately",
            Commuting_hire_1a: "In the main airport corridor, there are numerous car rental agencies. After choosing a car, you will be directed to the parking area marked with the letter",
            Commuting_hire_1b: "on the map above, where your vehicle will be located.",
            Commuting_hire_2: "<strong>Take detailed photos of the car's condition within the first minutes of rental.</strong>",
            Commuting_hire_3: "The rental cost starts at",
            Commuting_hire_4: "20-30 € / day",
            Commuting_hire_5: "The amount depends on the age of the renter and the length of the rental period.",
            Commuting: "Commuting",
          },
      },
      pl: {
        translation: {
            car: "samochód",
            Contact_us: "Skontaktuj się z nami",
            Mail: "Mail",
            Telephone: "Telefon",
            header: "nagłówek",
            Sightseeing: "Zwiedzanie",
            About_us: "Oferta",
            Our_offer: "Nasza oferta",
            Furnishings: "Wyposażenie i udogodnienia",
            Back: "Powrót",
            Shared_Swimming_pool: "Wspólny basen",
            Free_Parking: "Darmowy parking",
            No_Smoking_Allowed: "Zakaz palenia",
            No_Pets_Allowed: "Zakaz wprowadzania zwierząt",
            Washing_Machine_And_Dryer: "Pralka i suszarka",
            Soap_Shampoo: "Mydło, szampon",
            Towels_Linen_Toilet_Paper: "Ręczniki, pościel, papier toaletowy",
            Wardrobe_With_Hangers: "Szafa z wieszakami",
            Iron: "Żelazko",
            Tv_With_Smart_Tv: "Telewizor z funkcją Smart TV",
            Wi_Fi: "Wi-Fi",
            Crib_Chair_Toys_For_The_Little_Ones: "Łóżeczko, krzesło, zabawki dla dzieci",
            Microwave_Kettle_Cooktop_Toaster: "Mikrofalówka, czajnik, kuchenka, toster",
            Crockery_And_Cutlery: "Naczynia i sztućce",
            Refrigerator_And_Freezer: "Lodówka i zamrażarka",
            Coffee_Machine: "Ekspres do kawy",
            Terrace_Or_Balcony_Furniture: "Taras lub balkon, meble",
            Garden_View: "Widok na ogród",
            About_Costa_Calma: "O Costa Calma",
            Apartment_Info1: "Witamy na <strong>{{island.props.children}}</strong> – wyspie, która zachwyca turystów swoim surowym pięknem, złocistymi plażami i krystalicznie czystymi wodami oceanu. Jeśli marzysz o idealnych wakacjach, pełnych słońca, relaksu i niezapomnianych widoków, nasze apartamenty <strong>{{colores23.props.children}}</strong> i <strong>{{colores27.props.children}}</strong> w Costa Calma czekają właśnie na Ciebie.",
            Apartment_Info2: "Oferujemy 2 w pełni urządzone apartamenty z tarasem, z miejscem do spania dla 4 osób w każdym.",
            Apartment_Info3: "Apartamenty znajdują się na zamkniętym osiedlu z dostępem do basenu oraz sklepem znajdującym się 20 metrów od wejścia.",
            Apartment_1_description: "Szukasz idealnego miejsca na <strong>wakacje na Fuerteventurze?</strong> Apartament <strong> Colores de Fuerteventura 23</strong> w miejscowości <strong> Costa Calma</strong> to nowoczesne, przytulne wnętrze, które zapewnia wyjątkowy komfort i relaks. Po gruntownym remoncie w 2023 roku, apartament oferuje <strong> wysoki standard</strong> wykończenia, zaprojektowany zmyślą o wymagających Gościach. Położony jest w malowniczym kompleksie z basenem i palmami.",
            Apartment_1_description_2: "Apartament Colores de Fuerteventura 23 zlokalizowany na parterze, z <strong> tarasem w cieniu egzotycznych roślin</strong>, gwarantuje spokojny wypoczynek w kameralnej atmosferze. Goście mogą cieszyć się urokliwym wnętrzem w stylu <strong> boho</strong> - w pastelowych kolorach wyspy. Zaprojektowany został przez designera i zyskał wyjątkowy wakacyjny, wyspiarski charakter.",
            Apartment_1_description_3: "Apartament Colores de Fuerteventura 23 to doskonała baza wypadowa do odkrywania piękna wyspy. Położony w pobliżu <strong>plaży Sotavento</strong>, która znajduje się zaledwie 700 metrów od apartamentu, oferuje Gościom szybki dostęp do jednej z najpiękniejszych plaż na wyspie. W bliskiej okolicy znajduje się również centrum handlowe, restauracje, bary oraz lokalny bazar, gdzie można poczuć egzotyczną atmosferę Fuerteventury.",
            Apartment_header_text: "Komfortowy apartament po remoncie – Twój prywatny raj na Fuerteventurze",
            Apartment_header_text_2: "Idealna lokalizacja – blisko plaży i lokalnych atrakcji",
            Apartment_reservation_header: "Zarezerwuj swój pobyt już dziś!",
            Apartment_1_reservation_1: "Jeśli szukasz spokoju i bliskości natury, Apartament Colores de Fuerteventura 23 jest idealnym wyborem na Twoje wakacje.",
            Apartment_1_reservation_2: "Fuerteventura czeka na Ciebie – zanurz się w jej wyjątkowej atmosferze i ciesz się niezapomnianymi wakacjami na jednej z najpiękniejszych wysp Kanaryjskich.",
            Apartment_2_description: "Szukasz idealnego miejsca na <strong> wakacje na Fuerteventurze?</strong> Apartament <strong> Colores de Fuerteventura 27</strong> w miejscowości <strong> Costa Calma</strong> to nowoczesne, przytulne wnętrze, które zapewnia wyjątkowy komfort i relaks. Po gruntownym remoncie w 2023 roku, apartament oferuje <strong> wysoki standard</strong> wykończenia, zaprojektowany zmyślą o wymagających Gościach. Położony jest w malowniczym kompleksie z basenem i palmami.",
            Apartment_2_description_2: "Apartament Colores de Fuerteventura 27 zlokalizowany na pierwszym piętrze, z <strong> tarasem w cieniu egzotycznych roślin</strong>, gwarantuje spokojny wypoczynek w kameralnej atmosferze. Goście mogą cieszyć się urokliwym wnętrzem w stylu <strong> boho</strong> - w pastelowych kolorach wyspy. Zaprojektowany został przez designera i zyskał wyjątkowy wakacyjny, wyspiarski charakter.",
            Apartment_2_description_3: "Apartament Colores de Fuerteventura 27 to doskonała baza wypadowa do odkrywania piękna wyspy. Położony w pobliżu <strong>plaży Sotavento</strong>, która znajduje się zaledwie 700 metrów od apartamentu, oferuje Gościom szybki dostęp do jednej z najpiękniejszych plaż na wyspie. W bliskiej okolicy znajduje się również centrum handlowe, restauracje, bary oraz lokalny bazar, gdzie można poczuć egzotyczną atmosferę Fuerteventury.",
            Apartment_2_reservation_1: "Jeśli szukasz spokoju i bliskości natury, Apartament Colores de Fuerteventura 27 jest idealnym wyborem na Twoje wakacje.",
            Apartment_2_reservation_2: "Fuerteventura czeka na Ciebie – zanurz się w jej wyjątkowej atmosferze i ciesz się niezapomnianymi wakacjami na jednej z najpiękniejszych wysp Kanaryjskich.",
            Costa_Calma_Info: "Małe i ciche miasteczko na południowo-zachodnim wybrzeżu wyspy, z dużą ilością zieleni i palm. Przez środek miejscowości prowadzi piękna palmowa aleja będąca także miejscem spacerów oraz znakomitego joggingu bez względu na porę dnia i słońce. Costa Calma to idealne miejsce dla osób szukających ciszy i spokoju, szczególnie dla rodzin z dziećmi ze względu na plaże, spokojne wody i fale (kąpieliska z ratownikami) oraz amatorów sportów wodnych. W miasteczku 3 supermarkety, liczne sklepy i butiki, stacja benzynowa, poczta, apteki oraz doraźna pomoc medyczna. Jeżeli nie chcemy zajmować się gotowaniem to na miejsce jest duża ilość barów i restauracji przystępnych cenowo. Costa Calma to bardzo dobra baza wypadowa do zwiedzania wyspy jednak potrzebny jest samochód – na miejscu kilka wypożyczalni.",
            Popcorn_beach: "Popcorn beach – niespotykane nigdzie indziej miejsce z białymi kawałkami koralowca, które wyglądają jak popcorn.",
            Calderon_Hondo: "Wulkan Calderon Hondo – jeden z najmłodszych wulkanów na wyspie. Znajduje się niedaleko Lajeres. Wejście nie jest uciążliwe i trudne, ponieważ na obrzeża wulkanu idzie się wędrując wokół krateru, na szczycie wulkanu (278 m npm) znajduje się platforma widokowa . Cała wycieczka w obie strony to ok 2 h spaceru.",
            Mirador_de_Guise_y_Ayose: "Mirador de Guise y Ayose oraz Mirador Morro Velosa – dwa atrakcyjne punkty widokowe z przepięknymi widokami na dolinę Betancurii oraz Fuerteventurę – wspaniałe zdjęcia gwarantowane. Na Mirador de Guise y Ayose znajdują się dwa wielkie posągi ostatnich władców Guanczów (XV w.). Liczne wiewiórki, charakterystyczne dla tego rejonu.",
            Antigua: "Ciche i piękne miasteczko w środku Fuerteventury z ciekawym kościołem Nuestra Senora de Antigua znajdującym się na małym rynku w centrum miasteczka. Drugą ważną atrakcją jest muzeum produkcji serów – Museo del Queso Majorero. Przy parkingu możemy zwiedzić piękny duży młyn-wiatrak oraz ogród z dużą ilością różnorodnych kaktusów. W środku możliwość zapoznania się z warunkami, procesami produkcji i rodzajami serów kozich na Fuerteventura.",
            Betancuria: "Betancuria – dawna stolica Fuerteventura - obecnie małe turystyczne miasteczko, warty zwiedzenia przepiękny kościół Iglesia Catedral de Santa Maria  de Betancuria oraz liczne bary i kawiarenki.",
            Arco_de_las_planitas: "Inna piesza polecana wędrówka to Arco de las Penitas. Dojazd samochodem do podnóża wzniesienia a potem około 2 godzinna trasa do wapiennych form skalnych oraz pięknego wąwozu i starej nieczynnej tamy.",
            Ajuy: "Malutka wioska z piękną zatoką z czarny piaskiem, na miejscu 3 bardzo dobre restauracje z owocami morza (4 os + butelka wina ok 100€) . Bezpośrednio z plaży prowadzi ścieżka do jaskiń Piratów Cuevas de Ajuy (ok 20 min) – dobry punkt widokowy.",
            Aguas_Verdes: "Aguas Verdes to skaliste wybrzeże z licznymi klifami oraz naturalnie utworzonymi basenami – można się w nich kąpać.",
            Caleta_de_Fuste: "Caleta de Fuste – turystyczne miasteczko z piękną plażą i portem jachtowym oraz licznymi barami i knajpkami. W pobliżu liczne pola golfowe. Około 2 km na południe Muzeum Soli – można się tam dostać spacerem z miasteczka. Dodatkową atrakcją w okolicy jest tzw. Krabbenfelsen – naturalny basen powstający w czasie odpływów.",
            Oasis_Park: "Dla osób z dziećmi i nie tylko sporą atrakcją jest zoologiczno-botaniczny Oasis Park -odległość ok 10 km – dojazd samochodem, taxi lub autobus z ronda przy wjeździe do miasta. Zwiedzanie 3-5 godzin, ale dużo miejsc zacienionych. Dużą atrakcją dla dzieci jest karmienie żyraf, wielbłądów i innych zwierząt. Dodatkowo bardzo rozległy ogród botaniczny z przeróżnymi odmianami kaktusów.",
            Tarajalejo: "Tarajalejo to małe miasteczko zaledwie kilka kilometrów od Costa Calma, położone nad malowniczą zatoką. Nad brzegiem Oceany w kierunku wschodnim godzinnym spacerem można dotrzeć do pięknej jaskini . We wschodniej części miasteczka nad samym brzegiem polecamy lokalną restaurację La Barracca z dużą ilością potraw z ryb i owoców morza oraz znakomitym Vino de la casa.",
            La_Pared: "La Pred – wioska na północnym wybrzeżu, której największą atrakcją są zachody słońca oraz jest to idealne miejsce do uprawiania surfingu. W Costa Calma jest kilka miejsc z instruktorami – zapewniają transport i sprzęt. Uwaga - północne plaże są mało dostępne i dość niebezpieczne ze względu na falowanie i prądy podwodne. Na miejscu polecamy znakomitą restaurację Caretta Beach La Pared – super jedzenie i wino a na deser przepiękne widoki na Ocean . W miasteczku typowo surferska atmosfera oraz dwa bary z super drinkami – w piątki muzyka na żywo.",
            Playa_de_Sotavento: "Playa de Sotavento – najsłynniejsza i najbardziej znana plaża na wyspie i nie tylko. To przepiękna piaszczysta plaża, krystalicznie czysta turkusowa woda a przede wszystkim łagodne falowanie Oceanu. Ze względu na położenie i kształt plaży oraz zatoki w czasie odpływu powstaje  przepiękny widok laguny. Plaża Sotavento zaczyna się od Costa Calma i ciągnie się przez ponad 20 km do Morro Jable. Na końcu plaży w Costa Calma w kierunku płd-zach polecamy znakomity bar nad brzegiem Oceanu – Plamita Pepe y Antonia – fantastyczna ryba i owoce morza.",
            Pico_de_Zarza: "Pico de Zarza to najwyższe wzniesienie na Fuerteventura 807 m n.p.m. Jest to jedna z bardziej atrakcyjnych wycieczek pieszych na wyspie. Z parkingu u podnóża góry na sam szczyt dotrzemy w około 2 godziny spokojnej wędrówki (na dół ok 1 h) . Po drodze spotkamy liczne wiewiórki i kozy. Konieczne zabranie wody, wierzchniego okrycia –szczególnie w czasie wietrznej pogody. Z samego szczytu wspaniały widok na zachodni kraniec wyspy z Moro Jable oraz plażę Cofete wraz z willą Wintera.",
            Cofete: "Cofete – piękna odludna plaża na którą dostać się można właśnie z Moro Jable samochodem po szutrowej i nieutwardzonej drodze (uwaga – wypożyczalnie nie gwarantują ubezpieczenia na tego typu wyprawy). Po drodze szansa na odwiedzenie Willi Wintera, ale konieczne jest umówienie wizyty. Po drodze warto trochę zboczyć z drogi w kierunku najdalej wysuniętej na zachód części wyspy i zobaczyć latarnię morską Punta de Jandia .",
            Airbnb_reservation: "Zarezerwuj na Airbnb",
            Host: "Administratorem apartamentów jest Robert Superhost Airbnb",
            Fuerteventura_description: "<strong>{{island.props.children}}</strong> jako jedna z najstarszych wysp Kanaryjskich oferuje mnóstwo atrakcji.<br /><br />Odwiedź <strong>{{park.props.children}}</strong>, gdzie możesz podziwiać ogromne wydmy i malownicze krajobrazy, albo <strong>{{oasis.props.children}}</strong> – wyjątkowy ogród zoologiczny i botaniczny. Nie przegap <strong>{{cotillo.props.children}}</strong> – uroczą wioskę rybacką z pięknymi plażami i XVIII-wieczną fortecą. Odkryj niesamowite plaże <strong>{{sotavento.props.children}}</strong> i <strong>{{cofete.props.children}}</strong>, gdzie natura zachwyca swoją dzikością. Odwiedź <strong>{{ajuy.props.children}}</strong> – prehistoryczne jaskinie, jedną z najstarszych części wyspy. <strong>{{island.props.children}}</strong> to również raj dla miłośników sportów wodnych – od surfingu po nurkowanie.",
            Climate: "Klimat",
            Fuerteventura: "Fuerteventura",
            Place_select: "Wybierz miejsce na mapie, aby dowiedzieć się więcej.",
            Privacy_respect: "Szanujemy Twoją prywatność.",
            Cookies_consent: "Chcemy korzystać z ciasteczek (tzw. plików cookies), aby zapewnić Ci wygodne korzystanie z portalu <strong>{{webpage.props.children}}</strong> oraz dostęp do najnowszych usprawnień, które tworzymy dzięki prowadzonej analityce. Jeśli zgadzasz się na korzystanie ze wszystkich ciasteczek wyłącznie do celów statystycznych, kliknij „Zaakceptuj”. Możesz zawsze wycofać zgodę, zmieniając ustawienia cookies, usuwając je lub zmieniając ustawienia przeglądarki.",
            Accept: "Zaakceptuj",
            Close: "Zamknij",
            Climate_title: "Klimat rajskiej wyspy Fuerteventura",
            Climate_description_1: "Fuerteventura, jedna z najstarszych wysp w archipelagu <strong>Wysp Kanaryjskich</strong>, słynie ze swojego unikalnego klimatu i spektakularnych krajobrazów. Dzięki swojemu położeniu na Oceanie Atlantyckim, zaledwie 100 kilometrów od wybrzeży Afryki, wyspa cieszy się łagodnym klimatem półpustynnym o subtropikalnym charakterze. Jest to miejsce, gdzie różnorodność naturalna łączy się z wyjątkowymi warunkami pogodowymi, które przyciągają turystów z całego świata przez cały rok.",
            Climate_description_2: "Na <strong>Fuerteventurze</strong> praktycznie nie występują duże wahania temperatur, a średnie wartości oscylują pomiędzy 18°C zimą a 30°C latem. Charakterystyczne dla wyspy są jednak wiatry pasatowe, które niosą chłodzący wpływ oceanu i przyczyniają się do tego, że klimat pozostaje przyjemnie łagodny, nawet podczas letnich miesięcy. To właśnie te wiatry, wiejące regularnie od północnego wschodu, przyniosły <strong>Fuerteventurze</strong> przydomek „wyspy wiatru”. Są one szczególnie korzystne dla miłośników sportów wodnych, takich jak windsurfing i kitesurfing, czyniąc z Fuerteventury jedną z najlepszych lokalizacji do ich uprawiania.",
            Climate_description_3: "Poniżej znajduje się szczegółowy opis klimatu i warunków pogodowych panujących w miejscowości <strong>Costa Calma</strong>, z podziałem na pory roku, co ułatwi planowanie pobytu w zależności od preferencji pogodowych i aktywności.",
            Spring: "Wiosna (marzec - maj)",
            Summer: "Lato (czerwiec – sierpień)",
            Autumn: "Jesień (wrzesień – listopad)",
            Winter: "Zima (grudzień – luty)",
            Spring_text_1: "<strong>Temperatura:</strong> Wiosną temperatury wahają się od 20°C do 25°C w ciągu dnia, natomiast noce są nieco chłodniejsze, osiągając około 15°C – 18°C.",
            Spring_text_2: "<strong>Opady:</strong> Wiosną opady są bardzo rzadkie, co sprawia, że krajobraz pozostaje suchy i półpustynny. Sporadyczne przelotne deszcze zdarzają się na początku wiosny.",
            Spring_text_3: "<strong>Wiatr:</strong> Wiatry pasatowe zaczynają nasilać się w tym okresie, szczególnie w maju, co sprawia, że wyspa staje się rajem dla surferów, windsurferów i kitesurferów.",
            Spring_text_4: "<strong>Wilgotność:</strong> Wilgotność jest stosunkowo niska, co sprzyja komfortowi nawet przy wyższych temperaturach.",
            Spring_text_5: "<strong>Morze:</strong> Temperatura wody wiosną oscyluje wokół 18-20°C, co dla niektórych osób może być nieco chłodne, choć nadal możliwe do kąpieli.",
            Summer_text_1: "<strong>Temperatura:</strong> Latem temperatury wahają się między 25°C a 30°C, choć mogą przekraczać 30°C, szczególnie podczas napływu gorącego powietrza znad Sahary (zjawisko kalimy). Nocą temperatury są przyjemne, wynosząc od 20°C do 24°C.",
            Summer_text_2: "<strong>Opady:</strong> Lato na Fuerteventurze jest praktycznie bezdeszczowe, a opady w tym okresie to prawdziwa rzadkość.",
            Summer_text_3: "<strong>Wiatr:</strong> Wiatry pasatowe wieją najsilniej w lecie, co ochładza wyspę i sprawia, że upały są znacznie bardziej znośne.",
            Summer_text_4: "<strong>Wilgotność:</strong> Latem wilgotność powietrza jest niska, co ogranicza odczucie duchoty, mimo wysokich temperatur.",
            Summer_text_5: "<strong>Morze:</strong> Temperatura oceanu wzrasta do 22-23°C, co jest idealne dla kąpieli i sportów wodnych.",
            Autumn_text_1: "<strong>Temperatura:</strong> Jesienią temperatury są bardzo łagodne, z dziennymi wartościami od 24°C do 28°C i nocami w zakresie 19°C – 22°C.",
            Autumn_text_2: "<strong>Opady:</strong> Jesień jest jedynym okresem, kiedy mogą występować bardziej zauważalne opady, choć nadal są one rzadkie i nie trwają długo.",
            Autumn_text_3: "<strong>Wiatr:</strong> Jesienne wiatry słabną w porównaniu do lata, co sprzyja nieco spokojniejszym warunkom, idealnym dla turystów, którzy preferują mniej wietrzną pogodę.",
            Autumn_text_4: "<strong>Wilgotność:</strong> Wzrost wilgotności jest odczuwalny, ale nadal pozostaje umiarkowany.",
            Autumn_text_5: "<strong>Morze:</strong> Temperatura wody jesienią to przyjemne 22-24°C, co przyciąga miłośników sportów wodnych i kąpieli.",
            Winter_text_1: "<strong>Temperatura:</strong> Zima na Fuerteventurze jest wyjątkowo łagodna – temperatury w ciągu dnia osiągają 19°C – 22°C, a nocą spadają do 15°C – 18°C.",
            Winter_text_2: "<strong>Opady:</strong> Grudzień i styczeń to miesiące, w których pojawia się nieco więcej deszczu, choć nadal jest to niewielka ilość.",
            Winter_text_3: "<strong>Wiatr:</strong> Zimą wiatr jest znacznie słabszy niż latem, co sprawia, że wyspa staje się spokojniejsza i bardziej odpowiednia dla turystów preferujących łagodniejsze warunki pogodowe.",
            Winter_text_4: "<strong>Wilgotność:</strong> Wilgotność wzrasta do średniego poziomu, co sprawia, że klimat jest nieco bardziej wilgotny niż latem.",
            Winter_text_5: "<strong>Morze:</strong> Temperatura oceanu spada do około 18-20°C, ale nadal można uprawiać sporty wodne i kąpać się, choć dla niektórych może być to chłodne.",
            Commuting_info: "Na tej stronie znajdziesz szczegółowe informacje o dostępnych opcjach dojazdu do naszych apartamentów z lotniska <strong>Puerto del Rosario</strong>. Poniżej znajduje się również mapka lotniska, która ułatwi orientację i zaplanowanie podróży.",
            Commuting_tiadhe_1: "Do apartamentów dostaniesz się przy pomocy autobusów miejskich Tiadhe <strong>linia 10</strong>. Autobusu Tiadhe oznaczone są kolorem <strong>(biało-turkusowym lub granatowym)</strong>. Przystanek znajduje się przed wejściem głównym. Na metalowym słupie znajdować się będzie rozkład jazdy autobusów.",
            Commuting_tiadhe_2: "Link do strony przewoźnika Tiadhe i rozkładu linii numer 10 znajdziesz ",
            Commuting_tiadhe_3: "tutaj",
            Commuting_tiadhe_4: "Koszt biletu wyniesie około",
            Commuting_tiadhe_5: "10 € / osoba",
            Commuting_shuttle_1: "Po wyjściu z głównego korytarza lotniska kieruj się w prawo wzdłuż drogi, za postojem taksówek podążaj dalej prosto. Przystanek autokarów będzie znajdować się przed tobą - szukaj <strong>pomarańczowych autokarów</strong>.",
            Commuting_shuttle_2: "Na stronie przewoźnika",
            Commuting_shuttle_3: "wystarczy wybrać adres apartamentu, numer lotu oraz godzinę planowanego przylotu. Następnie do 72h przed przylotem otrzymasz maila z godziną odjazdu busa <strong>ten bus czeka na swoich pasażerów</strong>.",
            Commuting_shuttle_4: "Koszt biletu wyniesie około",
            Commuting_shuttle_5: "17 € / osoba",
            Commuting_taxi_1: "Po wyjściu z głównego korytarza lotniska kieruj się w prawo wzdłuż drogi, miejsce postoju taksówek oznakowane jest znakiem Taxi. Na miejscu mamy możliwość skorzystania jedynie z licencjonowanych <strong>białych taksówek</strong>. Aplikacje typu Uber/Bolt niestety nie są dostępne na terenie lotniska.",
            Commuting_taxi_2: "Cena przejazdu zależna będzie od taksometru.",
            Commuting_taxi_3: "Koszt przejazdu wyniesie około",
            Commuting_hire_1a: "W głównym korytarzu lotniska znajdują się liczne wypożyczalnie samochodów. Po wybraniu auta zostaniesz pokierowany na parkingu oznaczonym literą ",
            Commuting_hire_1b: " na mapie wyżej, gdzie będzie znajdować się twój samochód.",
            Commuting_hire_2: "<strong>Zrób dokładne zdjęcia stanu auta w pierwszych minutach jego wypożyczenia.</strong>",
            Commuting_hire_3: "Koszt wypożyczenia zaczyna od",
            Commuting_hire_4: "20-30 € / dzień",
            Commuting_hire_5: "Kwota jest zależna od wieku osoby wypożyczającej oraz od długości okresu wypożyczenia.",
            Commuting: "Dojazd",
          },
      },
      de: {
        translation: {
            car: "Auto",
            Contact_us: "Kontaktiere uns",
            Mail: "Post",
            Telephone: "Telefon",
            header: "Überschrift",
            Sightseeing: "Besichtigung",            
            About_us: "Angebot",
            Our_offer: "Unser Angebot",
            Furnishings: "Einrichtung und Annehmlichkeiten",
            Back: "Zurückkehren",
            Shared_Swimming_pool: "Gemeinschaftspool",
            Free_Parking: "Kostenloses Parken",
            No_Smoking_Allowed: "Rauchen verboten",
            No_Pets_Allowed: "Keine Haustiere erlaubt",
            Washing_Machine_And_Dryer: "Waschmaschine und Trockner",
            Soap_Shampoo: "Seife, Shampoo",
            Towels_Linen_Toilet_Paper: "Handtücher, Bettwäsche, Toilettenpapier",
            Wardrobe_With_Hangers: "Kleiderschrank mit Bügeln",
            Iron: "Bügeleisen",
            Tv_With_Smart_Tv: "Fernseher mit Smart TV",
            Wi_Fi: "Wi-Fi",
            Crib_Chair_Toys_For_The_Little_Ones: "Kinderbett, Stuhl, Spielzeug für die Kleinen",
            Microwave_Kettle_Cooktop_Toaster: "Mikrowelle, Wasserkocher, Kochplatte, Toaster",
            Crockery_And_Cutlery: "Geschirr und Besteck",
            Refrigerator_And_Freezer: "Kühlschrank und Gefrierschrank",
            Coffee_Machine: "Kaffeemaschine",
            Terrace_Or_Balcony_Furniture: "Terrasse oder Balkon, Möbel",
            Garden_View: "Gartenblick",
            About_Costa_Calma: "Um Costa Calma",
            Apartment_Info1: "Willkommen auf <strong>{{island.props.children}}</strong> – einer Insel, die Touristen mit ihrer rauen Schönheit, goldenen Stränden und kristallklarem Ozeanwasser begeistert. Wenn Sie von einem perfekten Urlaub träumen, der voller Sonnenschein, Entspannung und unvergesslicher Ausblicke ist, warten unsere Apartments <strong>{{colores23.props.children}}</strong> und <strong>{{colores27.props.children}}</strong> in Costa Calma auf Sie.",
            Apartment_Info2: "Wir bieten 2 vollständig eingerichtete Apartments mit Terrasse, die Platz für 4 Personen bieten.",
            Apartment_Info3: "Die Apartments befinden sich in einer geschlossenen Wohnanlage mit Zugang zu einem Schwimmbad und einem Geschäft, das 20 Meter vom Eingang entfernt ist.",
            Apartment_1_description: "Suchen Sie den perfekten Ort für einen <strong>Urlaub auf Fuerteventura?</strong> Das Apartment <strong>Colores de Fuerteventura 23</strong> in der Stadt <strong>Costa Calma</strong> ist ein modernes, gemütliches Interieur, das außergewöhnlichen Komfort und Entspannung bietet. Nach einer umfassenden Renovierung im Jahr 2023 bietet die Wohnung einen <strong>hohen Standard</strong> an Ausstattung, der für anspruchsvolle Gäste entworfen wurde. Es befindet sich in einer malerischen Anlage mit Pool und Palmen.",
            Apartment_1_description_2: "Das Apartment Colores de Fuerteventura 23, im Erdgeschoss gelegen, mit einer <strong>Terrasse im Schatten exotischer Pflanzen</strong>, garantiert einen ruhigen Aufenthalt in einer gemütlichen Atmosphäre. Die Gäste können ein charmantes Interieur im <strong>Boho-Stil</strong> genießen – in den Pastellfarben der Insel. Es wurde von einem Designer entworfen und hat einen einzigartigen, sommerlichen, inseltypischen Charakter erhalten.",
            Apartment_1_description_3: "Das Apartment Colores de Fuerteventura 23 ist eine hervorragende Basis, um die Schönheit der Insel zu erkunden. In der Nähe des <strong>Sotavento-Strands</strong> gelegen, nur 700 Meter von der Wohnung entfernt, bietet es den Gästen schnellen Zugang zu einem der schönsten Strände der Insel. In der Nähe befindet sich auch ein Einkaufszentrum, Restaurants, Bars und ein lokaler Markt, auf dem Sie die exotische Atmosphäre von Fuerteventura erleben können.",
            Apartment_header_text: "Komfortable Wohnung nach der Renovierung – Ihr privates Paradies auf Fuerteventura",
            Apartment_header_text_2: "Perfekte Lage – in der Nähe des Strandes und lokaler Attraktionen",
            Apartment_reservation_header: "Buchen Sie Ihren Aufenthalt noch heute!",
            Apartment_1_reservation_1: "Wenn Sie Ruhe und Nähe zur Natur suchen, ist das Apartment Colores de Fuerteventura 23 die perfekte Wahl für Ihren Urlaub.",
            Apartment_1_reservation_2: "Fuerteventura wartet auf Sie – tauchen Sie ein in seine einzigartige Atmosphäre und genießen Sie einen unvergesslichen Urlaub auf einer der schönsten Kanarischen Inseln.",
            Apartment_2_description: "Suchen Sie den perfekten Ort für einen <strong>Urlaub auf Fuerteventura?</strong> Das Apartment <strong>Colores de Fuerteventura 27</strong> in der Stadt <strong>Costa Calma</strong> ist ein modernes, gemütliches Interieur, das außergewöhnlichen Komfort und Entspannung bietet. Nach einer umfassenden Renovierung im Jahr 2023 bietet die Wohnung einen <strong>hohen Standard</strong> an Ausstattung, der für anspruchsvolle Gäste entworfen wurde. Es befindet sich in einer malerischen Anlage mit Pool und Palmen.",
            Apartment_2_description_2: "Das Apartment Colores de Fuerteventura 27, das sich im ersten Stock mit einer <strong>Terrasse im Schatten exotischer Pflanzen</strong> befindet, garantiert einen ruhigen Aufenthalt in einer intimen Atmosphäre. Die Gäste können das charmante Interieur im <strong>Boho-Stil</strong> in den Pastellfarben der Insel genießen. Es wurde von einem Designer entworfen und hat einen einzigartigen, inseltypischen Urlaubscharakter erhalten.",
            Apartment_2_description_3: "Das Apartment Colores de Fuerteventura 27 ist eine hervorragende Basis, um die Schönheit der Insel zu erkunden. In der Nähe des <strong>Sotavento-Strands</strong> gelegen, nur 700 Meter von der Wohnung entfernt, bietet es den Gästen schnellen Zugang zu einem der schönsten Strände der Insel. In der Nähe befindet sich auch ein Einkaufszentrum, Restaurants, Bars und ein lokaler Markt, auf dem Sie die exotische Atmosphäre von Fuerteventura erleben können.",
            Apartment_2_reservation_1: "Wenn Sie Ruhe und Nähe zur Natur suchen, ist das Apartment Colores de Fuerteventura 27 die perfekte Wahl für Ihren Urlaub.",
            Apartment_2_reservation_2: "Fuerteventura wartet auf Sie – tauchen Sie ein in seine einzigartige Atmosphäre und genießen Sie einen unvergesslichen Urlaub auf einer der schönsten Kanarischen Inseln.",
            Costa_Calma_Info: "Ein kleines und ruhiges Städtchen an der Südwestküste der Insel, mit viel Grün und Palmen. Eine schöne Palmenallee führt durch die Mitte des Ortes und eignet sich hervorragend zum Spazieren und Joggen, egal zu welcher Tageszeit und bei welchem Wetter. Costa Calma ist ein idealer Ort für Menschen, die Ruhe und Frieden suchen, insbesondere für Familien mit Kindern aufgrund der Strände, ruhigen Gewässer und Wellen (Badestellen mit Rettungsschwimmern) sowie für Wassersportler. Im Städtchen gibt es 3 Supermärkte, zahlreiche Geschäfte und Boutiquen, eine Tankstelle, eine Post, Apotheken und eine Notfallversorgung. Wenn man nicht selbst kochen möchte, gibt es vor Ort viele preiswerte Bars und Restaurants. Costa Calma ist eine sehr gute Ausgangsbasis zur Erkundung der Insel, jedoch ist ein Auto notwendig – es gibt mehrere Autovermietungen vor Ort.",
            Popcorn_beach: "Popcorn beach – ein einzigartiger Ort mit weißen Korallenstücken, die wie Popcorn aussehen.",
            Calderon_Hondo: "Calderon Hondo Vulkan – einer der jüngsten Vulkane der Insel. Er befindet sich in der Nähe von Lajeres. Der Aufstieg ist weder anstrengend noch schwierig, da der Weg zum Rand des Vulkans um den Krater herumführt. Auf dem Gipfel des Vulkans (278 Meter über dem Meeresspiegel) befindet sich eine Aussichtsplattform. Die gesamte Hin- und Rückreise dauert etwa 2 Stunden.",
            Mirador_de_Guise_y_Ayose: "Mirador de Guise y Ayose und Mirador Morro Velosa – zwei attraktive Aussichtspunkte mit wunderschönen Ausblicken auf das Betancuria-Tal und Fuerteventura – großartige Fotos garantiert. Am Mirador de Guise y Ayose gibt es zwei große Statuen der letzten Guanchen-Herrscher (15. Jahrhundert). Zahlreiche Eichhörnchen, charakteristisch für diese Region.",
            Antigua: "Ein ruhiges und schönes Dorf in der Mitte von Fuerteventura mit der interessanten Kirche Nuestra Senora de Antigua, die sich auf einem kleinen Platz im Zentrum des Dorfes befindet. Eine weitere wichtige Attraktion ist das Käsemuseum – Museo del Queso Majorero. In der Nähe des Parkplatzes können Sie eine schöne große Windmühle und einen Garten mit einer Vielzahl von Kakteen besuchen. Drinnen können Sie die Bedingungen, Produktionsprozesse und Arten von Ziegenkäse auf Fuerteventura kennenlernen.",
            Betancuria: "Betancuria – die ehemalige Hauptstadt von Fuerteventura, heute ein kleines Touristendorf, das wegen der schönen Iglesia Catedral de Santa Maria de Betancuria und zahlreicher Bars und Cafés einen Besuch wert ist.",
            Arco_de_las_planitas: "Eine weitere empfohlene Wanderroute ist Arco de las Penitas. Man kann mit dem Auto bis zum Fuße des Hügels fahren und dann eine etwa 2-stündige Route zu Kalksteinformationen, einer schönen Schlucht und einem alten, stillgelegten Damm unternehmen.",
            Ajuy: "Ein kleines Dorf mit einer wunderschönen Bucht mit schwarzem Sand. Es gibt 3 sehr gute Fischrestaurants (Abendessen für 4 Personen mit einer Flasche Wein kostet etwa 100 €). Ein Weg vom Strand führt zu den Piratenhöhlen Cuevas de Ajuy (ca. 20 Minuten) – ein großartiger Aussichtspunkt.",
            Aguas_Verdes: "Aguas Verdes ist eine felsige Küste mit zahlreichen Klippen und natürlichen Pools – ideal zum Schwimmen.",
            Caleta_de_Fuste: "Caleta de Fuste – ein Touristendorf mit einem schönen Strand, Yachthafen und vielen Bars und Restaurants. In der Nähe gibt es mehrere Golfplätze. Etwa 2 km südlich befindet sich das Salzmuseum – zu Fuß vom Ort aus erreichbar. Eine weitere Attraktion in der Umgebung ist das sogenannte Krabbenfelsen – ein natürlicher Pool, der bei Ebbe entsteht.",
            Oasis_Park: "Für Familien mit Kindern und andere Besucher ist der Oasis Park, ein zoologisch-botanischer Garten, eine große Attraktion, etwa 10 km entfernt – erreichbar mit dem Auto, Taxi oder Bus vom Kreisverkehr am Stadteingang. Der Besuch dauert 3-5 Stunden, aber es gibt viele schattige Bereiche. Eine große Attraktion für Kinder ist das Füttern von Giraffen, Kamelen und anderen Tieren. Zusätzlich gibt es einen weitläufigen botanischen Garten mit verschiedenen Kakteenarten.",
            Tarajalejo: "Tarajalejo ist ein kleines Dorf nur wenige Kilometer von Costa Calma entfernt, gelegen an einer malerischen Bucht. Ein einstündiger Spaziergang entlang der Küste in Richtung Osten führt zu einer schönen Höhle. Im östlichen Teil des Dorfes, direkt am Ufer, empfehlen wir das lokale Restaurant La Barracca, das viele Fisch- und Meeresfrüchtegerichte sowie ausgezeichneten Hauswein anbietet.",
            La_Pared: "La Pared – ein Dorf an der Nordküste, wo die Hauptattraktionen die Sonnenuntergänge und die idealen Bedingungen zum Surfen sind. In Costa Calma gibt es mehrere Orte mit Lehrern, die Transport und Ausrüstung bereitstellen. Achtung – die nördlichen Strände sind schwer zugänglich und aufgrund der Wellen und Unterströmungen recht gefährlich. Vor Ort empfehlen wir das ausgezeichnete Caretta Beach La Pared Restaurant – großartiges Essen und Wein, und zum Dessert atemberaubende Ausblicke auf den Ozean. Das Dorf hat eine typische Surferatmosphäre, mit zwei Bars, die großartige Drinks anbieten – freitags mit Live-Musik.",
            Playa_de_Sotavento: "Playa de Sotavento – der berühmteste und bekannteste Strand der Insel und darüber hinaus. Es ist ein atemberaubender Sandstrand mit kristallklarem türkisfarbenem Wasser und sanften Wellen des Ozeans. Aufgrund der Lage und Form des Strandes entsteht bei Ebbe eine wunderschöne Lagune. Der Sotavento-Strand beginnt in Costa Calma und erstreckt sich über mehr als 20 km bis nach Morro Jable. Am südlichen Ende des Strandes in Costa Calma, in Richtung Südwesten, empfehlen wir die fantastische Bar Plamita Pepe y Antonia – sie serviert fantastische Fisch- und Meeresfrüchtegerichte direkt am Ozean.",
            Pico_de_Zarza: "Pico de Zarza ist der höchste Gipfel auf Fuerteventura und liegt auf 807 Metern über dem Meeresspiegel. Es ist eine der attraktivsten Wanderungen auf der Insel. Vom Parkplatz am Fuße des Berges dauert es etwa 2 Stunden gemütliches Wandern, um den Gipfel zu erreichen (der Abstieg dauert etwa 1 Stunde). Unterwegs begegnen Ihnen zahlreiche Eichhörnchen und Ziegen. Nehmen Sie unbedingt Wasser und eine Jacke mit, besonders bei windigem Wetter. Vom Gipfel aus bietet sich ein herrlicher Blick auf das westliche Ende der Insel, einschließlich Morro Jable und Cofete-Strand sowie der Winter-Villa.",
            Cofete: "Cofete – ein wunderschöner, abgelegener Strand, der von Morro Jable aus mit dem Auto über eine Schotterstraße erreichbar ist (Achtung – Mietwagenversicherungen decken normalerweise keine derartigen Ausflüge ab). Unterwegs haben Sie die Möglichkeit, die Winter-Villa zu besuchen, aber eine Terminvereinbarung ist erforderlich. Es lohnt sich, einen kleinen Umweg in Richtung des westlichsten Teils der Insel zu machen, um den Leuchtturm Punta de Jandia zu sehen.",            
            Airbnb_reservation: "Buchen Sie auf Airbnb",
            Host: "Der Verwalter der Wohnungen ist Robert Superhost Airbnb",
            Fuerteventura_description: "<strong>{{island.props.children}}</strong> als eine der ältesten Kanarischen Inseln bietet viele Attraktionen.<br /><br />Besuchen Sie <strong>{{park.props.children}}</strong>, wo Sie riesige Dünen und malerische Landschaften bewundern können, oder <strong>{{oasis.props.children}}</strong> – einen einzigartigen Zoo und botanischen Garten. Verpassen Sie nicht <strong>{{cotillo.props.children}}</strong> – ein charmantes Fischerdorf mit schönen Stränden und einer Festung aus dem 18. Jahrhundert. Entdecken Sie die beeindruckenden Strände von <strong>{{sotavento.props.children}}</strong> und <strong>{{cofete.props.children}}</strong>, wo die Natur mit ihrer wilden Schönheit beeindruckt. Besuchen Sie <strong>{{ajuy.props.children}}</strong> – prähistorische Höhlen, die zu den ältesten Teilen der Insel gehören. <strong>{{island.props.children}}</strong> ist auch ein Paradies für Wassersportbegeisterte – vom Surfen bis zum Tauchen.",
            Climate: "Klima",
            Fuerteventura: "Fuerteventura",
            Place_select: "Wählen Sie einen Ort auf der Karte aus, um mehr zu erfahren.",
            Privacy_respect: "Wir respektieren Ihre Privatsphäre.",
            Cookies_consent: "Wir möchten Cookies verwenden, um Ihnen eine bequeme Nutzung des Portals <strong>{{webpage.props.children}}</strong> und den Zugang zu den neuesten Verbesserungen zu ermöglichen, die wir durch laufende Analysen entwickeln. Wenn Sie der Verwendung aller Cookies ausschließlich zu statistischen Zwecken zustimmen, klicken Sie bitte auf 'Akzeptieren'. Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie die Cookie-Einstellungen ändern, Cookies löschen oder Ihre Browsereinstellungen anpassen.",
            Accept: "Akzeptieren",
            Close: "Schließen",
            Climate_title: "Das Klima der paradiesischen Insel Fuerteventura",
            Climate_description_1: "Fuerteventura, eine der ältesten Inseln im Archipel der <strong>Kanarischen Inseln</strong>, ist bekannt für ihr einzigartiges Klima und spektakuläre Landschaften. Dank seiner Lage im Atlantischen Ozean, nur 100 Kilometer von der afrikanischen Küste entfernt, genießt die Insel ein mildes, halbwüstenartiges Klima mit subtropischem Charakter. Es ist ein Ort, an dem sich natürliche Vielfalt mit außergewöhnlichen Wetterbedingungen vereint, die das ganze Jahr über Touristen aus aller Welt anziehen.",
            Climate_description_2: "Auf <strong>Fuerteventura</strong> gibt es praktisch keine großen Temperaturschwankungen, wobei die durchschnittlichen Werte zwischen 18°C im Winter und 30°C im Sommer liegen. Charakteristisch für die Insel sind jedoch die Passatwinde, die die kühlende Wirkung des Ozeans mit sich bringen und das Klima auch in den Sommermonaten angenehm mild halten. Diese regelmäßig aus Nordosten wehenden Winde haben <strong>Fuerteventura</strong> den Spitznamen „Insel des Windes“ eingebracht. Sie sind besonders vorteilhaft für Wassersportler wie Windsurfer und Kitesurfer und machen Fuerteventura zu einem der besten Orte für diese Aktivitäten.",
            Climate_description_3: "Nachfolgend finden Sie eine detaillierte Beschreibung des Klimas und der Wetterbedingungen in der Stadt <strong>Costa Calma</strong>, unterteilt nach Jahreszeiten, die Ihnen bei der Planung Ihres Aufenthalts entsprechend Ihren Wettervorlieben und Aktivitäten hilft.",
            Spring: "Frühling (März - Mai)",
            Summer: "Sommer (Juni - August)",
            Autumn: "Herbst (September - November)",
            Winter: "Winter (Dezember - Februar)",
            Spring_text_1: "<strong>Temperatur:</strong> Im Frühling schwanken die Temperaturen tagsüber zwischen 20°C und 25°C, während die Nächte etwas kühler sind und etwa 15°C - 18°C erreichen.",
            Spring_text_2: "<strong>Niederschlag:</strong> Niederschläge sind im Frühling sehr selten, was die Landschaft trocken und halbwüstenartig hält. Gelegentliche leichte Schauer treten zu Beginn des Frühlings auf.",
            Spring_text_3: "<strong>Wind:</strong> Die Passatwinde beginnen in dieser Zeit, insbesondere im Mai, stärker zu werden, wodurch die Insel zu einem Paradies für Surfer, Windsurfer und Kitesurfer wird.",
            Spring_text_4: "<strong>Feuchtigkeit:</strong> Die Luftfeuchtigkeit ist relativ niedrig, was auch bei höheren Temperaturen für Komfort sorgt.",
            Spring_text_5: "<strong>Meer:</strong> Die Wassertemperatur im Frühling liegt bei etwa 18-20°C, was für einige etwas kühl sein kann, aber dennoch zum Schwimmen geeignet ist.",
            Summer_text_1: "<strong>Temperatur:</strong> Im Sommer schwanken die Temperaturen zwischen 25°C und 30°C, können jedoch 30°C überschreiten, insbesondere während des Einströmens heißer Luft aus der Sahara (das Kalima-Phänomen). Nachts sind die Temperaturen angenehm und liegen zwischen 20°C und 24°C.",
            Summer_text_2: "<strong>Niederschlag:</strong> Der Sommer auf Fuerteventura ist praktisch niederschlagsfrei, und Niederschläge in dieser Zeit sind eine echte Seltenheit.",
            Summer_text_3: "<strong>Wind:</strong> Die Passatwinde wehen im Sommer am stärksten, was die Insel abkühlt und die Hitze viel erträglicher macht.",
            Summer_text_4: "<strong>Feuchtigkeit:</strong> Im Sommer ist die Luftfeuchtigkeit niedrig, was das Gefühl der Schwüle trotz hoher Temperaturen verringert.",
            Summer_text_5: "<strong>Meer:</strong> Die Wassertemperatur des Ozeans steigt auf 22-23°C, was ideal zum Schwimmen und für Wassersportarten ist.",
            Autumn_text_1: "<strong>Temperatur:</strong> Im Herbst sind die Temperaturen sehr mild, mit Tagestemperaturen von 24°C bis 28°C und Nachttemperaturen zwischen 19°C und 22°C.",
            Autumn_text_2: "<strong>Niederschlag:</strong> Der Herbst ist die einzige Zeit, in der es zu merklichen Niederschlägen kommen kann, obwohl sie weiterhin selten sind und nicht lange dauern.",
            Autumn_text_3: "<strong>Wind:</strong> Die Herbstwinde schwächen sich im Vergleich zum Sommer ab, was zu etwas ruhigeren Bedingungen führt, die ideal für Touristen sind, die weniger windiges Wetter bevorzugen.",
            Autumn_text_4: "<strong>Feuchtigkeit:</strong> Es ist ein spürbarer Anstieg der Luftfeuchtigkeit zu verzeichnen, die jedoch moderat bleibt.",
            Autumn_text_5: "<strong>Meer:</strong> Die Wassertemperatur im Herbst liegt bei angenehmen 22-24°C, was Wassersportler und Badegäste anzieht.",
            Winter_text_1: "<strong>Temperatur:</strong> Der Winter auf Fuerteventura ist außergewöhnlich mild – die Tagestemperaturen erreichen 19°C bis 22°C, während die Nachttemperaturen auf 15°C bis 18°C sinken.",
            Winter_text_2: "<strong>Niederschlag:</strong> Dezember und Januar sind Monate, in denen es etwas mehr regnet, obwohl es immer noch eine geringe Menge ist.",
            Winter_text_3: "<strong>Wind:</strong> Im Winter ist der Wind deutlich schwächer als im Sommer, was die Insel ruhiger macht und sie für Touristen geeigneter macht, die mildere Wetterbedingungen bevorzugen.",
            Winter_text_4: "<strong>Feuchtigkeit:</strong> Die Luftfeuchtigkeit steigt auf ein durchschnittliches Niveau, wodurch das Klima etwas feuchter als im Sommer wird.",
            Winter_text_5: "<strong>Meer:</strong> Die Wassertemperatur des Ozeans sinkt auf etwa 18-20°C, aber es ist immer noch möglich, Wassersport zu betreiben und zu schwimmen, obwohl es für einige kühl sein kann.",
            Commuting_info: "Auf dieser Seite findest du detaillierte Informationen zu den verfügbaren Transportmöglichkeiten, um unsere Apartments vom Flughafen <strong>Puerto del Rosario</strong> zu erreichen. Unten befindet sich auch eine Flughafenkarte, die dir bei der Orientierung und Planung deiner Reise hilft.",
            Commuting_tiadhe_1: "Zu den Apartments gelangen Sie mit den Stadtbussen von Tiadhe, <strong>Linie 10</strong>. Die Tiadhe-Busse sind <strong>(weiß-türkis oder dunkelblau)</strong> gekennzeichnet. Die Bushaltestelle befindet sich vor dem Haupteingang. Der Fahrplan wird auf einem Metallpfosten angebracht.",
            Commuting_tiadhe_2: "Den Link zur Website des Betreibers Tiadhe und den Fahrplan der Linie 10 finden Sie ",
            Commuting_tiadhe_3: "hier",
            Commuting_tiadhe_4: "Der Fahrpreis beträgt ca.",
            Commuting_tiadhe_5: "10 € / Person",   
            Commuting_shuttle_1: "Nachdem Sie den Hauptkorridor des Flughafens verlassen haben, gehen Sie rechts entlang der Straße, gehen Sie weiter geradeaus am Taxistand vorbei. Die Bushaltestelle befindet sich vor Ihnen - suchen Sie nach <strong>orangenen Bussen</strong>.",
            Commuting_shuttle_2: "Auf der Website des Anbieters",
            Commuting_shuttle_3: "wählen Sie einfach die Adresse der Wohnung, die Flugnummer und die geplante Ankunftszeit. Anschließend erhalten Sie bis zu 72 Stunden vor Ankunft eine E-Mail mit der Abfahrtszeit des Busses. <strong>Dieser Bus wartet auf seine Passagiere</strong>.",
            Commuting_shuttle_4: "Der Fahrpreis beträgt ca.",
            Commuting_shuttle_5: "17 € / Person",
            Commuting_taxi_1: "Nachdem Sie den Hauptkorridor des Flughafens verlassen haben, gehen Sie rechts entlang der Straße, die Taxistation ist mit einem Taxischild gekennzeichnet. Hier stehen nur lizenzierte <strong>weiße Taxis</strong> zur Verfügung. Leider sind Uber/Bolt-Apps am Flughafen nicht verfügbar.",
            Commuting_taxi_2: "Der Fahrpreis richtet sich nach dem Taxameter.",
            Commuting_taxi_3: "Die Fahrtkosten betragen etwa",
            Commuting_hire_1a: "Im Hauptkorridor des Flughafens gibt es zahlreiche Autovermietungen. Nach der Auswahl des Autos wirst du zum Parkplatz geleitet, der mit dem Buchstaben ",
            Commuting_hire_1b: " auf der obigen Karte gekennzeichnet ist, wo sich dein Fahrzeug befindet.",
            Commuting_hire_2: "<strong>Machen Sie in den ersten Minuten der Anmietung detaillierte Fotos vom Zustand des Fahrzeugs.</strong>",
            Commuting_hire_3: "Die Mietkosten beginnen bei",
            Commuting_hire_4: "20-30 € / Tag",
            Commuting_hire_5: "Der Betrag hängt vom Alter des Mieters und der Dauer der Mietzeit ab.",                  
            Commuting: "Anfahrt",
          },
      },
      es: {
        translation: {
            car: "coche",
            Contact_us: "Contacta con nosotros",
            Mail: "Correo",
            Telephone: "Teléfono",
            header: "encabezado",
            About_us: "Oferta",
            Sightseeing: "Pasear",
            Our_offer: "Nuestra oferta",
            Furnishings: "Equipos y comodidades",
            Back: "Devolver",
            Shared_Swimming_pool: "Piscina compartida",
            Free_Parking: "Estacionamiento gratuito",
            No_Smoking_Allowed: "No se permite fumar",
            No_Pets_Allowed: "No se permiten mascotas",
            Washing_Machine_And_Dryer: "Lavadora y secadora",
            Soap_Shampoo: "Jabón, champú",
            Towels_Linen_Toilet_Paper: "Toallas, sábanas, papel higiénico",
            Wardrobe_With_Hangers: "Armario con perchas",
            Iron: "Plancha",
            Tv_With_Smart_Tv: "Televisor con Smart TV",
            Wi_Fi: "Wi-Fi",
            Crib_Chair_Toys_For_The_Little_Ones: "Cuna, silla, juguetes para los pequeños",
            Microwave_Kettle_Cooktop_Toaster: "Microondas, hervidor, cocina, tostadora",
            Crockery_And_Cutlery: "Vajilla y cubiertos",
            Refrigerator_And_Freezer: "Refrigerador y congelador",
            Coffee_Machine: "Cafetera",
            Terrace_Or_Balcony_Furniture: "Terraza o balcón, muebles",
            Garden_View: "Vista al jardín",
            About_Costa_Calma: "Acerca de Costa Calma",
            Apartment_Info1: "Bienvenido a <strong>{{island.props.children}}</strong> – una isla que cautiva a los turistas con su belleza natural, sus playas doradas y sus aguas cristalinas del océano. Si sueñas con las vacaciones perfectas llenas de sol, relajación y vistas inolvidables, nuestros apartamentos <strong>{{colores23.props.children}}</strong> y <strong>{{colores27.props.children}}</strong> en Costa Calma te están esperando.",
            Apartment_Info2: "Ofrecemos 2 apartamentos totalmente amueblados con terraza, cada uno con capacidad para 4 personas.",
            Apartment_Info3: "Los apartamentos están situados en un complejo cerrado con acceso a una piscina y una tienda ubicada a 20 metros de la entrada.",
            Apartment_1_description: "¿Buscas el lugar perfecto para unas <strong>vacaciones en Fuerteventura?</strong> El apartamento <strong>Colores de Fuerteventura 23</strong> en la localidad de <strong>Costa Calma</strong> es un interior moderno y acogedor que ofrece un confort y relajación excepcionales. Tras una completa renovación en 2023, el apartamento ofrece un <strong>alto estándar</strong> de acabado, diseñado para huéspedes exigentes. Está ubicado en un pintoresco complejo con piscina y palmeras.",
            Apartment_1_description_2: "El apartamento Colores de Fuerteventura 23, ubicado en la planta baja, con una <strong>terraza a la sombra de plantas exóticas</strong>, garantiza una estancia tranquila en un ambiente íntimo. Los huéspedes pueden disfrutar de un encantador interior de estilo <strong>boho</strong> en los colores pastel de la isla. Fue diseñado por un diseñador y ha adquirido un carácter vacacional y isleño único.",
            Apartment_1_description_3: "El apartamento Colores de Fuerteventura 23 es una excelente base para explorar la belleza de la isla. Ubicado cerca de la <strong>Playa de Sotavento</strong>, a solo 700 metros del apartamento, ofrece a los huéspedes un acceso rápido a una de las playas más hermosas de la isla. En los alrededores también hay un centro comercial, restaurantes, bares y un mercado local, donde puedes sentir la atmósfera exótica de Fuerteventura.",
            Apartment_header_text: "Cómodo apartamento renovado – Tu paraíso privado en Fuerteventura",
            Apartment_header_text_2: "Ubicación perfecta – cerca de la playa y atracciones locales",
            Apartment_reservation_header: "¡Reserva tu estancia hoy!",
            Apartment_1_reservation_1: "Si buscas paz y cercanía con la naturaleza, el apartamento Colores de Fuerteventura 23 es la opción perfecta para tus vacaciones.",
            Apartment_1_reservation_2: "Fuerteventura te espera – sumérgete en su atmósfera única y disfruta de unas vacaciones inolvidables en una de las islas más hermosas de Canarias.",
            Apartment_2_description: "¿Buscas el lugar perfecto para unas <strong>vacaciones en Fuerteventura?</strong> El apartamento <strong>Colores de Fuerteventura 27</strong> en la localidad de <strong>Costa Calma</strong> es un interior moderno y acogedor que ofrece un confort y relajación excepcionales. Tras una completa renovación en 2023, el apartamento ofrece un <strong>alto estándar</strong> de acabado, diseñado para huéspedes exigentes. Está ubicado en un pintoresco complejo con piscina y palmeras.",
            Apartment_2_description_2: "El apartamento Colores de Fuerteventura 27, situado en la primera planta con una <strong>terraza a la sombra de plantas exóticas</strong>, garantiza una estancia tranquila en un ambiente íntimo. Los huéspedes pueden disfrutar del encantador interior de estilo <strong>boho</strong> en los colores pastel de la isla. Fue diseñado por un diseñador y ha adquirido un carácter vacacional isleño único.",
            Apartment_2_description_3: "El apartamento Colores de Fuerteventura 27 es una excelente base para explorar la belleza de la isla. Ubicado cerca de la <strong>Playa de Sotavento</strong>, a solo 700 metros del apartamento, ofrece a los huéspedes un acceso rápido a una de las playas más hermosas de la isla. En los alrededores también hay un centro comercial, restaurantes, bares y un mercado local, donde puedes sentir la atmósfera exótica de Fuerteventura.",
            Apartment_2_reservation_1: "Si buscas paz y cercanía con la naturaleza, el apartamento Colores de Fuerteventura 27 es la opción perfecta para tus vacaciones.",
            Apartment_2_reservation_2: "Fuerteventura te espera – sumérgete en su atmósfera única y disfruta de unas vacaciones inolvidables en una de las islas más hermosas de Canarias.",
            Costa_Calma_Info: "Un pueblo pequeño y tranquilo en la costa suroeste de la isla, con mucha vegetación y palmeras. Una hermosa avenida de palmeras atraviesa el centro del pueblo, convirtiéndolo en un lugar excelente para pasear y hacer jogging a cualquier hora del día y bajo cualquier clima. Costa Calma es un lugar ideal para aquellos que buscan paz y tranquilidad, especialmente familias con niños debido a sus playas, aguas tranquilas y olas (zonas de baño con salvavidas) y los aficionados a los deportes acuáticos. En el pueblo hay 3 supermercados, numerosas tiendas y boutiques, una gasolinera, una oficina de correos, farmacias y atención médica de urgencia. Si no se quiere cocinar, hay muchos bares y restaurantes asequibles en el lugar. Costa Calma es una muy buena base para explorar la isla, pero es necesario un coche – hay varias agencias de alquiler de coches disponibles localmente.",
            Popcorn_beach: "Popcorn beach – un lugar único con trozos blancos de coral que parecen palomitas de maíz.",
            Calderon_Hondo: "Volcán Calderon Hondo – uno de los volcanes más jóvenes de la isla. Se encuentra cerca de Lajeres. La subida no es agotadora ni difícil, ya que el camino hacia el borde del volcán rodea el cráter. En la cima del volcán (278 metros sobre el nivel del mar), hay una plataforma de observación. Todo el viaje de ida y vuelta dura unas 2 horas.",
            Mirador_de_Guise_y_Ayose: "Mirador de Guise y Ayose y Mirador Morro Velosa – dos atractivos miradores con hermosas vistas del valle de Betancuria y Fuerteventura – fotos geniales garantizadas. En Mirador de Guise y Ayose, hay dos grandes estatuas de los últimos gobernantes guanches (siglo XV). Numerosas ardillas, características de esta región.",
            Antigua: "Un tranquilo y hermoso pueblo en el centro de Fuerteventura con la interesante iglesia de Nuestra Senora de Antigua ubicada en una pequeña plaza en el centro del pueblo. Otra atracción importante es el museo de producción de queso – Museo del Queso Majorero. Cerca del área de estacionamiento, puedes visitar un hermoso gran molino de viento y un jardín con una variedad de cactus. Dentro, puedes aprender sobre las condiciones, procesos de producción y tipos de quesos de cabra en Fuerteventura.",
            Betancuria: "Betancuria – la antigua capital de Fuerteventura, ahora un pequeño pueblo turístico que vale la pena visitar por la hermosa Iglesia Catedral de Santa María de Betancuria y sus numerosos bares y cafeterías.",
            Arco_de_las_planitas: "Otra ruta de senderismo recomendada es Arco de las Peñitas. Se puede llegar en coche hasta la base de la colina y luego hacer un recorrido de unas 2 horas para ver formaciones de roca caliza, un hermoso barranco y una antigua presa inactiva.",
            Ajuy: "Un pequeño pueblo con una hermosa bahía de arena negra. Hay 3 muy buenos restaurantes de mariscos (una cena para 4 personas con una botella de vino cuesta alrededor de 100 €). Un sendero desde la playa lleva a las Cuevas de los Piratas, Cuevas de Ajuy (unos 20 minutos) – un excelente mirador.",
            Aguas_Verdes: "Aguas Verdes es una costa rocosa con numerosos acantilados y piscinas naturales, perfectas para nadar.",
            Caleta_de_Fuste: "Caleta de Fuste – un pueblo turístico con una hermosa playa, un puerto deportivo y numerosos bares y restaurantes. Cerca se encuentran varios campos de golf. Aproximadamente a 2 km al sur está el Museo de la Sal, al que se puede llegar caminando desde el pueblo. Otra atracción en la zona es el llamado Krabbenfelsen, una piscina natural que se forma durante la marea baja.",
            Oasis_Park: "Para familias con niños y otros, una gran atracción es el Oasis Park, un jardín zoológico y botánico ubicado a unos 10 km, accesible en coche, taxi o autobús desde la rotonda a la entrada del pueblo. La visita dura entre 3 y 5 horas, pero hay muchas zonas de sombra. Una gran atracción para los niños es alimentar a las jirafas, camellos y otros animales. Además, hay un extenso jardín botánico con diversas especies de cactus.",
            Tarajalejo: "Tarajalejo es un pequeño pueblo a solo unos kilómetros de Costa Calma, situado junto a una pintoresca bahía. Un paseo de una hora a lo largo de la costa hacia el este lleva a una hermosa cueva. En la parte este del pueblo, justo en la orilla, recomendamos el restaurante local La Barracca, que ofrece una gran variedad de platos de pescado y mariscos, así como un excelente vino de la casa.",
            La_Pared: "La Pared – un pueblo en la costa norte, donde las principales atracciones son las puestas de sol y las condiciones ideales para practicar surf. En Costa Calma hay varios lugares con instructores que proporcionan transporte y equipo. Atención: las playas del norte son poco accesibles y bastante peligrosas debido a las olas y corrientes submarinas. En el lugar, recomendamos el excelente restaurante Caretta Beach La Pared, con comida y vino estupendos, y como postre, impresionantes vistas al océano. El pueblo tiene una típica atmósfera surfista, con dos bares que ofrecen excelentes bebidas – música en vivo los viernes.",
            Playa_de_Sotavento: "Playa de Sotavento – la playa más famosa y conocida de la isla y más allá. Es una impresionante playa de arena con aguas cristalinas y turquesas y suaves olas del océano. Debido a la ubicación y forma de la playa, durante la marea baja se forma una hermosa laguna. La playa de Sotavento comienza en Costa Calma y se extiende por más de 20 km hasta Morro Jable. Al final de la playa en Costa Calma, hacia el suroeste, recomendamos el fantástico bar Plamita Pepe y Antonia, que ofrece pescado y mariscos increíbles justo al borde del océano.",
            Pico_de_Zarza: "Pico de Zarza es el pico más alto de Fuerteventura, con 807 metros sobre el nivel del mar. Es una de las excursiones de senderismo más atractivas de la isla. Desde el aparcamiento en la base de la montaña, se tarda aproximadamente 2 horas de caminata tranquila para llegar a la cima (alrededor de 1 hora para descender). En el camino, encontrarás numerosas ardillas y cabras. Es imprescindible llevar agua y una chaqueta, especialmente en días ventosos. Desde la cima se disfruta de una magnífica vista del extremo occidental de la isla, incluyendo Morro Jable y la playa de Cofete, junto con la Villa Winter.",
            Cofete: "Cofete – una hermosa playa remota a la que se accede desde Morro Jable en coche por un camino de grava sin pavimentar (atención: las compañías de alquiler de coches no suelen cubrir este tipo de excursiones). En el camino, tienes la oportunidad de visitar la Villa Winter, pero es necesario concertar una cita. Vale la pena desviarse un poco hacia la parte más occidental de la isla para ver el faro de Punta de Jandía.",
            Airbnb_reservation: "Reserva en Airbnb",
            Host: "El administrador de los apartamentos es Robert Superhost Airbnb",
            Fuerteventura_description: "<strong>{{island.props.children}}</strong> como una de las islas más antiguas de Canarias ofrece muchas atracciones.<br /><br />Visita <strong>{{park.props.children}}</strong>, donde puedes admirar vastas dunas y paisajes pintorescos, o <strong>{{oasis.props.children}}</strong> – un zoológico y jardín botánico único. No te pierdas <strong>{{cotillo.props.children}}</strong> – un encantador pueblo de pescadores con hermosas playas y una fortaleza del siglo XVIII. Descubre las impresionantes playas de <strong>{{sotavento.props.children}}</strong> y <strong>{{cofete.props.children}}</strong>, donde la naturaleza sorprende con su belleza salvaje. Visita <strong>{{ajuy.props.children}}</strong> – cuevas prehistóricas, una de las partes más antiguas de la isla. <strong>{{island.props.children}}</strong> también es un paraíso para los amantes de los deportes acuáticos – desde el surf hasta el buceo.",
            Climate: "Clima",
            Fuerteventura: "Fuerteventura",
            Place_select: "Seleccione una ubicación en el mapa para obtener más información.",
            Privacy_respect: "Respetamos tu privacidad.",
            Cookies_consent: "Nos gustaría utilizar cookies para brindarte una experiencia cómoda en el portal <strong>{{webpage.props.children}}</strong> y acceso a las últimas mejoras que creamos a través de análisis continuos. Si aceptas el uso de todas las cookies exclusivamente con fines estadísticos, haz clic en 'Aceptar'. Puedes retirar tu consentimiento en cualquier momento cambiando la configuración de cookies, eliminándolas o ajustando la configuración de tu navegador.",
            Accept: "Aceptar",
            Close: "Cerrar",
            Climate_title: "El Clima de la Isla Paraíso de Fuerteventura",
            Climate_description_1: "Fuerteventura, una de las islas más antiguas del archipiélago de las <strong>Islas Canarias</strong>, es conocida por su clima único y paisajes espectaculares. Gracias a su ubicación en el Océano Atlántico, a solo 100 kilómetros de la costa africana, la isla disfruta de un clima semi-desértico suave con carácter subtropical. Es un lugar donde la diversidad natural se une a condiciones climáticas excepcionales, atrayendo a turistas de todo el mundo durante todo el año.",
            Climate_description_2: "En <strong>Fuerteventura</strong> prácticamente no hay grandes fluctuaciones de temperatura, con valores promedio que oscilan entre los 18°C en invierno y los 30°C en verano. Sin embargo, la isla es conocida por sus vientos alisios, que aportan la influencia refrescante del océano y ayudan a mantener el clima agradablemente suave, incluso durante los meses de verano. Estos vientos, que soplan regularmente desde el noreste, han dado a <strong>Fuerteventura</strong> el apodo de 'Isla del Viento'. Son especialmente favorables para los entusiastas de los deportes acuáticos, como el windsurf y el kitesurf, convirtiendo a Fuerteventura en una de las mejores ubicaciones para practicar estos deportes.",
            Climate_description_3: "A continuación se presenta una descripción detallada del clima y las condiciones meteorológicas en la localidad de <strong>Costa Calma</strong>, dividida por estaciones, lo que facilitará la planificación de su estancia según sus preferencias climáticas y actividades.",
            Spring: "Primavera (marzo - mayo)",
            Summer: "Verano (junio - agosto)",
            Autumn: "Otoño (septiembre - noviembre)",
            Winter: "Invierno (diciembre - febrero)",
            Spring_text_1: "<strong>Temperatura:</strong> En primavera, las temperaturas oscilan entre 20°C y 25°C durante el día, mientras que las noches son ligeramente más frescas, alcanzando alrededor de 15°C - 18°C.",
            Spring_text_2: "<strong>Precipitaciones:</strong> Las precipitaciones son muy raras en primavera, lo que mantiene el paisaje seco y semiárido. Ocurren lluvias ligeras ocasionales al comienzo de la primavera.",
            Spring_text_3: "<strong>Viento:</strong> Los vientos alisios comienzan a intensificarse durante este período, especialmente en mayo, convirtiendo a la isla en un paraíso para surfistas, windsurfistas y kiters.",
            Spring_text_4: "<strong>Humedad:</strong> La humedad es relativamente baja, lo que contribuye a la comodidad incluso a temperaturas más altas.",
            Spring_text_5: "<strong>Mar:</strong> La temperatura del agua en primavera ronda los 18-20°C, lo que puede ser un poco fresco para algunos, aunque aún es apto para nadar.",
            Summer_text_1: "<strong>Temperatura:</strong> En verano, las temperaturas oscilan entre 25°C y 30°C, aunque pueden superar los 30°C, especialmente durante la llegada de aire caliente del Sahara (el fenómeno de la calima). Las temperaturas nocturnas son agradables, entre 20°C y 24°C.",
            Summer_text_2: "<strong>Precipitaciones:</strong> El verano en Fuerteventura es prácticamente sin lluvias, y las precipitaciones en este período son una verdadera rareza.",
            Summer_text_3: "<strong>Viento:</strong> Los vientos alisios soplan más fuerte en verano, enfriando la isla y haciendo que el calor sea mucho más soportable.",
            Summer_text_4: "<strong>Humedad:</strong> En verano, la humedad es baja, lo que reduce la sensación de bochorno a pesar de las altas temperaturas.",
            Summer_text_5: "<strong>Mar:</strong> La temperatura del océano sube a 22-23°C, lo que es ideal para nadar y practicar deportes acuáticos.",
            Autumn_text_1: "<strong>Temperatura:</strong> En otoño, las temperaturas son muy suaves, con valores diurnos que oscilan entre 24°C y 28°C y temperaturas nocturnas entre 19°C y 22°C.",
            Autumn_text_2: "<strong>Precipitaciones:</strong> El otoño es el único período en el que pueden ocurrir precipitaciones más notables, aunque aún son raras y no duran mucho.",
            Autumn_text_3: "<strong>Viento:</strong> Los vientos de otoño son más débiles en comparación con los de verano, lo que contribuye a condiciones más tranquilas, ideales para turistas que prefieren un clima menos ventoso.",
            Autumn_text_4: "<strong>Humedad:</strong> Hay un aumento notable en la humedad, pero sigue siendo moderada.",
            Autumn_text_5: "<strong>Mar:</strong> La temperatura del agua en otoño es de 22-24°C, lo que atrae a entusiastas de los deportes acuáticos y bañistas.",
            Winter_text_1: "<strong>Temperatura:</strong> El invierno en Fuerteventura es excepcionalmente suave: las temperaturas diurnas alcanzan entre 19°C y 22°C, mientras que las nocturnas bajan entre 15°C y 18°C.",
            Winter_text_2: "<strong>Precipitaciones:</strong> Diciembre y enero son meses en los que hay algo más de lluvia, aunque sigue siendo una cantidad pequeña.",
            Winter_text_3: "<strong>Viento:</strong> En invierno, el viento es significativamente más débil que en verano, lo que hace que la isla sea más tranquila y más adecuada para turistas que prefieren condiciones meteorológicas más suaves.",
            Winter_text_4: "<strong>Humedad:</strong> La humedad aumenta a un nivel promedio, lo que hace que el clima sea un poco más húmedo que en verano.",
            Winter_text_5: "<strong>Mar:</strong> La temperatura del océano baja a alrededor de 18-20°C, pero aún se pueden practicar deportes acuáticos y nadar, aunque puede ser fresco para algunos.",
            Commuting_info: "En esta página encontrarás información detallada sobre las opciones de transporte disponibles para llegar a nuestros apartamentos desde el aeropuerto <strong>Puerto del Rosario</strong>. También hay un mapa del aeropuerto a continuación para ayudarte con la orientación y planificación de tu viaje.",
            Commuting_tiadhe_1: "Puedes llegar a los apartamentos utilizando los autobuses urbanos de Tiadhe, <strong>línea 10</strong>. Los autobuses de Tiadhe están marcados en <strong>(blanco-turquesa o azul marino)</strong>. La parada de autobús está ubicada frente a la entrada principal. El horario estará publicado en un poste metálico.",
            Commuting_tiadhe_2: "Puede encontrar el enlace a la página del operador Tiadhe y al horario de la línea 10 ",
            Commuting_tiadhe_3: "aquí",
            Commuting_tiadhe_4: "El costo del boleto será de alrededor de",
            Commuting_tiadhe_5: "10 € / persona",
            Commuting_shuttle_1: "Después de salir del corredor principal del aeropuerto, dirígete a la derecha por la carretera, continúa recto pasando la parada de taxis. La parada del autobús estará frente a ti: busca <strong>autobuses naranjas</strong>.",
            Commuting_shuttle_2: "En la página del operador",
            Commuting_shuttle_3: "solo selecciona la dirección del apartamento, el número de vuelo y la hora prevista de llegada. Luego, hasta 72 horas antes de la llegada, recibirás un correo electrónico con la hora de salida del autobús. <strong>Este autobús espera a sus pasajeros</strong>.",
            Commuting_shuttle_4: "El costo del boleto será de alrededor de",
            Commuting_shuttle_5: "17 € / persona",
            Commuting_taxi_1: "Después de salir del corredor principal del aeropuerto, dirígete a la derecha por la carretera, la parada de taxis está marcada con un letrero de Taxi. Solo hay <strong>taxis blancos con licencia</strong> disponibles aquí. Desafortunadamente, las aplicaciones como Uber/Bolt no están disponibles en el aeropuerto.",
            Commuting_taxi_2: "El costo del viaje depende del taxímetro.",
            Commuting_taxi_3: "El costo del viaje será aproximadamente",
            Commuting_hire_1a: "En el corredor principal del aeropuerto, hay numerosas agencias de alquiler de coches. Después de elegir un coche, serás dirigido al área de estacionamiento marcada con la letra ",
            Commuting_hire_1b: " en el mapa arriba, donde estará ubicado tu vehículo.",
            Commuting_hire_2: "<strong>Tome fotos detalladas de las condiciones del coche en los primeros minutos del alquiler.</strong>",
            Commuting_hire_3: "El costo de alquiler comienza en",
            Commuting_hire_4: "20-30 € / día",
            Commuting_hire_5: "El monto depende de la edad del arrendatario y de la duración del período de alquiler.",      
            Commuting: "Desplazamiento",
          },
      },
      it: {
        translation: {
          car: "auto",
          Contact_us: "Contattaci",
          Mail: "Email",
          Telephone: "Telefono",
          header: "intestazione",
          About_us: "Offerta",
          Sightseeing: "Attrazioni turistiche",
          Furnishings: "Arredamento e servizi",
          Back: "Indietro",
          Shared_Swimming_pool: "Piscina condivisa",
          Free_Parking: "Parcheggio gratuito",
          No_Smoking_Allowed: "Divieto di fumare",
          No_Pets_Allowed: "Animali non ammessi",
          Washing_Machine_And_Dryer: "Lavatrice e asciugatrice",
          Soap_Shampoo: "Sapone, shampoo",
          Towels_Linen_Toilet_Paper: "Asciugamani, biancheria, carta igienica",
          Wardrobe_With_Hangers: "Armadio con grucce",
          Iron: "Ferro da stiro",
          Tv_With_Smart_Tv: "TV con Smart TV",
          Wi_Fi: "Wi-Fi",
          Crib_Chair_Toys_For_The_Little_Ones: "Culla, seggiolone, giocattoli per i più piccoli",
          Microwave_Kettle_Cooktop_Toaster: "Microonde, bollitore, piano cottura, tostapane",
          Crockery_And_Cutlery: "Stoviglie e posate",
          Refrigerator_And_Freezer: "Frigorifero e congelatore",
          Coffee_Machine: "Macchina da caffè",
          Terrace_Or_Balcony_Furniture: "Terrazza o balcone, mobili",
          Garden_View: "Vista giardino",
          About_Costa_Calma: "Informazioni su Costa Calma",
          Apartment_Info1: "Benvenuto a <strong>{{island.props.children}}</strong> – un'isola che cattura i turisti con la sua bellezza aspra, le spiagge dorate e le acque oceaniche cristalline. Se sogni la vacanza perfetta, piena di sole, relax e viste indimenticabili, i nostri appartamenti <strong>{{colores23.props.children}}</strong> e <strong>{{colores27.props.children}}</strong> a Costa Calma ti stanno aspettando.",
          Apartment_Info2: "Offriamo 2 appartamenti completamente arredati con terrazza, ciascuno in grado di ospitare 4 persone.",
          Apartment_Info3: "Gli appartamenti si trovano in un complesso residenziale con accesso a una piscina e a un negozio situato a 20 metri dall'ingresso.",
          Apartment_1_description: "Cerchi il posto perfetto per una <strong>vacanza a Fuerteventura?</strong> L'appartamento <strong>Colores de Fuerteventura 23</strong> nella città di <strong>Costa Calma</strong> ha un arredamento moderno e accogliente che offre comfort e relax eccezionali. Dopo una completa ristrutturazione nel 2023, l'appartamento offre uno <strong>standard elevato</strong> di finiture, progettato tenendo a mente gli ospiti più esigenti. Si trova in un complesso pittoresco con piscina e palme.",
          Apartment_1_description_2: "L'appartamento Colores de Fuerteventura 23, situato al piano terra con una <strong>terrazza ombreggiata da piante esotiche</strong>, garantisce un soggiorno tranquillo in un'atmosfera intima. Gli ospiti possono godere dell'incantevole arredamento in stile boho nei <strong>colori pastello</strong> dell'isola. È stato progettato da un designer e ha acquisito un carattere unico di vacanza e isola.",
          Apartment_1_description_3: "L'appartamento Colores de Fuerteventura 23 è un'eccellente base per esplorare la bellezza dell'isola. Situato vicino a <strong>Sotavento Beach</strong>, a solo 700 metri dall'appartamento, offre agli ospiti un rapido accesso a una delle spiagge più belle dell'isola. Nelle vicinanze ci sono anche un centro commerciale, ristoranti, bar e un mercato locale dove puoi vivere l'atmosfera esotica di Fuerteventura.",
          Apartment_header_text: "Appartamento confortevole dopo ristrutturazione – Il tuo paradiso privato a Fuerteventura",
          Apartment_header_text_2: "Posizione perfetta – vicino alla spiaggia e alle attrazioni locali",
          Apartment_reservation_header: "Prenota il tuo soggiorno oggi!",
          Apartment_1_reservation_1: "Se cerchi pace e vicinanza alla natura, l'appartamento Colores de Fuerteventura 23 è la scelta perfetta per la tua vacanza.",
          Apartment_1_reservation_2: "Fuerteventura ti aspetta – immergiti nella sua atmosfera unica e goditi una vacanza indimenticabile in una delle più belle isole Canarie.",
          Apartment_2_description: "Cerchi il posto perfetto per una <strong>vacanza a Fuerteventura?</strong> L'appartamento <strong>Colores de Fuerteventura 27</strong> nella città di <strong>Costa Calma</strong> ha un arredamento moderno e accogliente che offre comfort e relax eccezionali. Dopo una completa ristrutturazione nel 2023, l'appartamento offre uno <strong>standard elevato</strong> di finiture, progettato tenendo a mente gli ospiti più esigenti. Si trova in un complesso pittoresco con piscina e palme.",
          Apartment_2_description_2: "L'appartamento Colores de Fuerteventura 27, situato al primo piano con una <strong>terrazza ombreggiata da piante esotiche</strong>, garantisce un soggiorno tranquillo in un'atmosfera intima. Gli ospiti possono godere dell'incantevole arredamento in stile boho nei <strong>colori pastello</strong> dell'isola. È stato progettato da un designer e ha acquisito un carattere unico di vacanza e isola.",
          Apartment_2_description_3: "L'appartamento Colores de Fuerteventura 27 è un'eccellente base per esplorare la bellezza dell'isola. Situato vicino a <strong>Sotavento Beach</strong>, a solo 700 metri dall'appartamento, offre agli ospiti un rapido accesso a una delle spiagge più belle dell'isola. Nelle vicinanze ci sono anche un centro commerciale, ristoranti, bar e un mercato locale dove puoi vivere l'atmosfera esotica di Fuerteventura.",
          Apartment_2_reservation_1: "Se cerchi pace e vicinanza alla natura, l'appartamento Colores de Fuerteventura 27 è la scelta perfetta per la tua vacanza.",
          Apartment_2_reservation_2: "Fuerteventura ti aspetta – immergiti nella sua atmosfera unica e goditi una vacanza indimenticabile in una delle più belle isole Canarie.",          
          Costa_Calma_Info: "Una piccola e tranquilla cittadina sulla costa sud-occidentale dell'isola, con molta vegetazione e palme. Un bellissimo viale fiancheggiato da palme attraversa il centro della città, rendendola un luogo ideale per passeggiate e jogging in qualsiasi momento della giornata e con qualsiasi tempo. Costa Calma è un luogo ideale per chi cerca pace e tranquillità, in particolare per le famiglie con bambini grazie alle sue spiagge, alle acque calme e alle onde (zone balneari con bagnini) e per gli appassionati di sport acquatici. La città dispone di 3 supermercati, numerosi negozi e boutique, una stazione di servizio, un ufficio postale, farmacie e cure mediche d'emergenza. Se non vuoi cucinare, ci sono molti bar e ristoranti convenienti in loco. Costa Calma è una base molto buona per esplorare l'isola, ma è necessario un'auto – sono disponibili diverse agenzie di noleggio auto locali.",
          Popcorn_beach: "Popcorn beach – un luogo unico con pezzi bianchi di corallo che sembrano popcorn.",
          Calderon_Hondo: "Vulcano Calderon Hondo – uno dei vulcani più giovani dell'isola. Si trova vicino a Lajeres. La salita non è gravosa né difficile, poiché il sentiero per il bordo del vulcano gira intorno al cratere. In cima al vulcano (278 metri sul livello del mare), c'è una piattaforma panoramica. L'intero viaggio di andata e ritorno dura circa 2 ore.",
          Mirador_de_Guise_y_Ayose: "Mirador de Guise y Ayose e Mirador Morro Velosa – due punti panoramici con splendide viste sulla valle di Betancuria e Fuerteventura – foto spettacolari garantite. Al Mirador de Guise y Ayose, ci sono due grandi statue degli ultimi sovrani Guanci (XV secolo). Numerosi scoiattoli, caratteristici di questa regione.",
          Antigua: "Un tranquillo e bellissimo paese al centro di Fuerteventura con l'interessante chiesa di Nuestra Señora de Antigua situata in una piccola piazza del centro del paese. Un'altra importante attrazione è il museo della produzione di formaggi – Museo del Queso Majorero. Vicino all'area di parcheggio, si può visitare un bellissimo grande mulino a vento e un giardino con diverse varietà di cactus. All'interno, è possibile conoscere le condizioni, i processi di produzione e i tipi di formaggi di capra a Fuerteventura.",
          Betancuria: "Betancuria – l'ex capitale di Fuerteventura, ora una piccola cittadina turistica che merita una visita per la bellissima Iglesia Catedral de Santa María de Betancuria e numerosi bar e caffè.",
          Arco_de_las_planitas: "Un altro sentiero escursionistico consigliato è Arco de las Peñitas. Puoi arrivare alla base della collina in auto e poi fare un percorso di 2 ore per vedere formazioni rocciose calcaree, una splendida gola e una vecchia diga inattiva.",
          Ajuy: "Un minuscolo villaggio con una bellissima baia con sabbia nera. Ci sono 3 ottimi ristoranti di pesce (una cena per 4 con una bottiglia di vino costa circa €100). Un sentiero dalla spiaggia porta alle Grotte dei Pirati, Cuevas de Ajuy (circa 20 minuti) – un ottimo punto panoramico.",
          Aguas_Verdes: "Aguas Verdes è una costa rocciosa con numerose scogliere e piscine naturali – perfette per nuotare.",
          Caleta_de_Fuste: "Caleta de Fuste – una città turistica con una bellissima spiaggia, un porto turistico e numerosi bar e ristoranti. Nelle vicinanze ci sono diversi campi da golf. Circa 2 km a sud si trova il Museo del Sale – si può raggiungere a piedi dalla città. Un'altra attrazione della zona è il cosiddetto Krabbenfelsen – una piscina naturale formata durante la bassa marea.",
          Oasis_Park: "Per famiglie con bambini e altri, un'attrazione importante è l'Oasis Park, un giardino zoologico e botanico situato a circa 10 km – raggiungibile in auto, taxi o autobus dalla rotonda all'ingresso della città. La visita dura dalle 3 alle 5 ore, ma ci sono molte zone ombreggiate. Una grande attrazione per i bambini è il dare da mangiare a giraffe, cammelli e altri animali. Inoltre, c'è un ampio giardino botanico con varie specie di cactus.",
          Tarajalejo: "Tarajalejo è una piccola città a pochi chilometri da Costa Calma, situata vicino a una baia pittoresca. Una passeggiata di un'ora lungo la costa oceanica verso est ti porterà a una bellissima grotta. Nella parte orientale della città, proprio sulla riva, consigliamo il ristorante locale La Barracca, che offre una varietà di piatti di pesce e frutti di mare e un ottimo vino della casa.",
          La_Pared: "La Pared – un villaggio sulla costa settentrionale, dove le principali attrazioni sono i tramonti e le condizioni ideali per il surf. A Costa Calma ci sono diversi luoghi con istruttori che forniscono trasporto e attrezzature. Nota – le spiagge settentrionali sono meno accessibili e piuttosto pericolose a causa delle onde e delle correnti sottomarine. Sul posto, consigliamo l'ottimo ristorante Caretta Beach La Pared – ottimo cibo e vino, e per dessert, splendide viste sull'oceano. Il villaggio ha un'atmosfera tipicamente surfista, con due bar che offrono ottimi drink – musica dal vivo il venerdì.",
          Playa_de_Sotavento: "Playa de Sotavento – la spiaggia più famosa e rinomata dell'isola e oltre. È una splendida spiaggia di sabbia con acqua turchese cristallina e onde oceaniche dolci. Grazie alla posizione e alla forma della spiaggia, durante la bassa marea si forma una bellissima laguna. La spiaggia di Sotavento parte da Costa Calma e si estende per oltre 20 km fino a Morro Jable. All'estremità meridionale della spiaggia a Costa Calma, verso sud-ovest, consigliamo il fantastico bar Plamita Pepe y Antonia – che serve fantastici pesce e frutti di mare proprio accanto all'oceano.",
          Pico_de_Zarza: "Pico de Zarza è il punto più alto di Fuerteventura, che si erge a 807 metri sul livello del mare. È uno dei viaggi escursionistici più attraenti dell'isola. Dal parcheggio alla base della montagna, ci vogliono circa 2 ore di facile escursione per raggiungere la vetta (circa 1 ora per scendere). Lungo il percorso, incontrerai numerosi scoiattoli e capre. Assicurati di portare acqua e una giacca, soprattutto in caso di vento. La vetta offre una magnifica vista sulla punta occidentale dell'isola, compresa la spiaggia di Morro Jable e Cofete, insieme alla Villa Winter.",
          Cofete: "Cofete – una bellissima spiaggia remota accessibile da Morro Jable in auto tramite una strada ghiaiosa e non asfaltata (nota – l'assicurazione dell'auto a noleggio di solito non copre questo tipo di viaggio). Lungo il percorso, hai la possibilità di visitare la Villa Winter, ma è necessario fissare un appuntamento. Vale la pena fare una breve deviazione verso il punto più occidentale dell'isola per vedere il faro di Punta de Jandia.",
          Airbnb_reservation: "Prenota su Airbnb",
          Host: "L'amministratore degli appartamenti è Robert Superhost Airbnb",
          Fuerteventura_description: "<strong>{{island.props.children}}</strong>, una delle isole Canarie più antiche, offre molte attrazioni.<br /><br />Visita <strong>{{park.props.children}}</strong>, dove puoi ammirare vaste dune e paesaggi pittoreschi, o <strong>{{oasis.props.children}}</strong> – uno zoo e giardino botanico unici. Non perdere <strong>{{cotillo.props.children}}</strong> – un affascinante villaggio di pescatori con spiagge bellissime e una fortezza del XVIII secolo. Scopri le splendide spiagge di <strong>{{sotavento.props.children}}</strong> e <strong>{{cofete.props.children}}</strong>, dove la natura affascina con la sua bellezza selvaggia. Visita <strong>{{ajuy.props.children}}</strong> – grotte preistoriche, una delle parti più antiche dell'isola. <strong>{{island.props.children}}</strong> è anche un paradiso per gli appassionati di sport acquatici, dal surf alle immersioni.",
          Climate: "Clima",
          Fuerteventura: "Fuerteventura",
          Place_select: "Seleziona un luogo sulla mappa per saperne di più.",
          Privacy_respect: "Rispettiamo la tua privacy.",
          Cookies_consent: "Vorremmo utilizzare i cookie per offrirti un'esperienza comoda sul portale <strong>{{webpage.props.children}}</strong> e l'accesso agli ultimi miglioramenti che creiamo attraverso analisi continue. Se accetti l'uso di tutti i cookie esclusivamente per scopi statistici, fai clic su 'Accetta.' Puoi sempre revocare il tuo consenso modificando le impostazioni dei cookie, eliminandoli o regolando le impostazioni del tuo browser.",
          Accept: "Accetta",
          Close: "Chiudi",
          Climate_title: "Il Clima dell'Isola Paradiso di Fuerteventura",
          Climate_description_1: "Fuerteventura, una delle isole più antiche dell'arcipelago delle <strong>Canarie</strong>, è rinomata per il suo clima unico e i paesaggi spettacolari. Grazie alla sua posizione nell'Oceano Atlantico, a soli 100 chilometri dalla costa africana, l'isola gode di un clima semi-desertico mite con un carattere subtropicale. È un luogo dove la diversità naturale incontra condizioni meteorologiche eccezionali, attirando turisti da tutto il mondo tutto l'anno.",
          Climate_description_2: "Su <strong>Fuerteventura</strong>, ci sono praticamente nessuna grande fluttuazione della temperatura, con valori medi che vanno dai 18°C in inverno ai 30°C in estate. Tuttavia, l'isola è conosciuta per i suoi venti alisei, che portano l'influenza rinfrescante dell'oceano e aiutano a mantenere il clima piacevolmente mite, anche durante i mesi estivi. Questi venti, che soffiano regolarmente da nord-est, hanno guadagnato a <strong>Fuerteventura</strong> il soprannome di 'Isola del Vento.' Sono particolarmente favorevoli per gli appassionati di sport acquatici, come il windsurf e il kitesurf, rendendo Fuerteventura una delle migliori location per queste attività.",
          Climate_description_3: "Di seguito è riportata una descrizione dettagliata del clima e delle condizioni meteorologiche nella città di <strong>Costa Calma</strong>, divisa per stagioni, che aiuterà a pianificare il soggiorno in base alle preferenze climatiche e alle attività.",
          Spring: "Primavera (marzo - maggio)",
          Summer: "Estate (giugno - agosto)",
          Autumn: "Autunno (settembre - novembre)",
          Winter: "Inverno (dicembre - febbraio)",
          Spring_text_1: "<strong>Temperatura:</strong> In primavera, le temperature variano dai 20°C ai 25°C durante il giorno, mentre le notti sono leggermente più fresche, raggiungendo circa 15°C - 18°C.",
          Spring_text_2: "<strong>Precipitazioni:</strong> Le precipitazioni sono molto rare in primavera, il che mantiene il paesaggio secco e semi-desertico. Occasionalmente si verificano leggeri acquazzoni all'inizio della primavera.",
          Spring_text_3: "<strong>Vento:</strong> I venti alisei iniziano a intensificarsi durante questo periodo, soprattutto a maggio, rendendo l'isola un paradiso per i surfisti, i windsurfisti e i kitesurfisti.",
          Spring_text_4: "<strong>Umidità:</strong> L'umidità è relativamente bassa, contribuendo al comfort anche a temperature più elevate.",
          Spring_text_5: "<strong>Mare:</strong> La temperatura dell'acqua in primavera si aggira intorno ai 18-20°C, che potrebbe essere un po' fresca per alcuni, ma comunque nuotabile.",
          Summer_text_1: "<strong>Temperatura:</strong> In estate, le temperature variano tra i 25°C e i 30°C, anche se possono superare i 30°C, soprattutto durante l'afflusso di aria calda dal Sahara (il fenomeno calima). Le temperature notturne sono piacevoli, variando dai 20°C ai 24°C.",
          Summer_text_2: "<strong>Precipitazioni:</strong> L'estate a Fuerteventura è praticamente priva di pioggia e le precipitazioni durante questo periodo sono una vera rarità.",
          Summer_text_3: "<strong>Vento:</strong> I venti alisei soffiano più forti in estate, rinfrescando l'isola e rendendo il caldo molto più sopportabile.",
          Summer_text_4: "<strong>Umidità:</strong> In estate, l'umidità è bassa, il che riduce la sensazione di afa nonostante le alte temperature.",
          Summer_text_5: "<strong>Mare:</strong> La temperatura dell'oceano sale a 22-23°C, ideale per nuotare e praticare sport acquatici.",
          Autumn_text_1: "<strong>Temperatura:</strong> In autunno, le temperature sono molto miti, con valori diurni che vanno dai 24°C ai 28°C e temperature notturne tra i 19°C e i 22°C.",
          Autumn_text_2: "<strong>Precipitazioni:</strong> L'autunno è l'unico periodo in cui possono verificarsi precipitazioni più evidenti, anche se sono ancora rare e non durano a lungo.",
          Autumn_text_3: "<strong>Vento:</strong> I venti autunnali si indeboliscono rispetto all'estate, contribuendo a condizioni leggermente più calme, ideali per i turisti che preferiscono un clima meno ventoso.",
          Autumn_text_4: "<strong>Umidità:</strong> Si osserva un aumento significativo dell'umidità, ma rimane moderata.",
          Autumn_text_5: "<strong>Mare:</strong> La temperatura dell'acqua in autunno è piacevole, tra i 22-24°C, attirando appassionati di sport acquatici e bagnanti.",
          Winter_text_1: "<strong>Temperatura:</strong> L'inverno a Fuerteventura è eccezionalmente mite: le temperature diurne raggiungono i 19°C ai 22°C, mentre le temperature notturne scendono a 15°C ai 18°C.",
          Winter_text_2: "<strong>Precipitazioni:</strong> Dicembre e gennaio sono mesi in cui si verifica un po' più di pioggia, anche se si tratta sempre di piccole quantità.",
          Winter_text_3: "<strong>Vento:</strong> In inverno, il vento è significativamente più debole rispetto all'estate, rendendo l'isola più tranquilla e adatta ai turisti che preferiscono condizioni meteorologiche più miti.",
          Winter_text_4: "<strong>Umidità:</strong> L'umidità aumenta a un livello medio, rendendo il clima leggermente più umido rispetto all'estate.",
          Winter_text_5: "<strong>Mare:</strong> La temperatura dell'oceano scende a circa 18-20°C, ma è ancora possibile praticare sport acquatici e nuotare, anche se potrebbe essere fresco per alcuni.",
          Commuting_info: "In questa pagina troverai informazioni dettagliate sulle opzioni di trasporto disponibili per raggiungere i nostri appartamenti dall'aeroporto di <strong>Puerto del Rosario</strong>. Qui sotto c'è anche una mappa dell'aeroporto per assisterti nella navigazione e pianificazione del tuo viaggio.",
          Commuting_tiadhe_1: "Puoi raggiungere gli appartamenti utilizzando gli autobus urbani Tiadhe, <strong>linea 10</strong>. Gli autobus Tiadhe sono contrassegnati in <strong>(bianco-turchese o blu navy)</strong>. La fermata dell'autobus si trova di fronte all'ingresso principale. Il programma sarà affisso su un palo metallico.",
          Commuting_tiadhe_2: "Puoi trovare un link al sito web di Tiadhe e il programma per la linea 10 ",
          Commuting_tiadhe_3: "qui",
          Commuting_tiadhe_4: "Il costo del biglietto sarà di circa",
          Commuting_tiadhe_5: "10 € / persona",
          Commuting_shuttle_1: "Dopo essere usciti dal corridoio principale dell'aeroporto, vai a destra lungo la strada, continua dritto oltre la fermata dei taxi. La fermata della navetta sarà di fronte a te - cerca <strong>autobus arancioni</strong>.",
          Commuting_shuttle_2: "Sul sito web del vettore",
          Commuting_shuttle_3: "seleziona semplicemente l'indirizzo dell'appartamento, il numero del volo e l'orario di arrivo programmato. Poi, fino a 72 ore prima dell'arrivo, riceverai un'email con l'orario di partenza della navetta. <strong>Questo autobus aspetta i suoi passeggeri</strong>.",
          Commuting_shuttle_4: "Il costo del biglietto sarà di circa",
          Commuting_shuttle_5: "17 € / persona",
          Commuting_taxi_1: "Dopo essere usciti dal corridoio principale dell'aeroporto, vai a destra lungo la strada, la fermata dei taxi è contrassegnata con un cartello Taxi. Qui sono disponibili solo taxi <strong>bianchi</strong> autorizzati. Sfortunatamente, le app Uber/Bolt non sono disponibili in aeroporto.",
          Commuting_taxi_2: "La tariffa dipende dal tassametro.",
          Commuting_taxi_3: "Il costo del viaggio sarà di circa",
          Commuting_hire_1a: "Nel corridoio principale dell'aeroporto, ci sono numerose agenzie di noleggio auto. Dopo aver scelto un'auto, verrai indirizzato all'area di parcheggio contrassegnata con la lettera ",
          Commuting_hire_1b: " sulla mappa sopra, dove si troverà il tuo veicolo.",
          Commuting_hire_2: "<strong>Scatta foto dettagliate delle condizioni dell'auto nei primi minuti di noleggio.</strong>",
          Commuting_hire_3: "Il costo del noleggio parte da",
          Commuting_hire_4: "20-30 € / giorno",
          Commuting_hire_5: "L'importo dipende dall'età del noleggiatore e dalla durata del periodo di noleggio.",
          Commuting: "Trasporto",
          },
      },
      dk: {
        translation: {
          car: "auto",
          Contact_us: "Neem contact met ons op",
          Mail: "E-mail",
          Telephone: "Telefoon",
          header: "koptekst",
          About_us: "Aanbod",
          Sightseeing: "Bezienswaardigheden",
          Furnishings: "Inrichting en voorzieningen",
          Back: "Terug",
          Shared_Swimming_pool: "Gedeeld zwembad",
          Free_Parking: "Gratis parkeren",
          No_Smoking_Allowed: "Roken niet toegestaan",
          No_Pets_Allowed: "Huisdieren niet toegestaan",
          Washing_Machine_And_Dryer: "Wasmachine en droger",
          Soap_Shampoo: "Zeep, shampoo",
          Towels_Linen_Toilet_Paper: "Handdoeken, linnengoed, toiletpapier",
          Wardrobe_With_Hangers: "Kast met hangers",
          Iron: "Strijkijzer",
          Tv_With_Smart_Tv: "TV met Smart TV",
          Wi_Fi: "Wi-Fi",
          Crib_Chair_Toys_For_The_Little_Ones: "Wieg, stoel, speelgoed voor de kleintjes",
          Microwave_Kettle_Cooktop_Toaster: "Magnetron, waterkoker, kookplaat, broodrooster",
          Crockery_And_Cutlery: "Servies en bestek",
          Refrigerator_And_Freezer: "Koelkast en vriezer",
          Coffee_Machine: "Koffiezetapparaat",
          Terrace_Or_Balcony_Furniture: "Terras of balkon, meubels",
          Garden_View: "Tuinzicht",
          About_Costa_Calma: "Over Costa Calma",
          Apartment_Info1: "Welkom op <strong>{{island.props.children}}</strong> – een eiland dat toeristen betovert met zijn ruige schoonheid, gouden stranden en kristalhelder oceaanwater. Als je droomt van de perfecte vakantie vol zon, ontspanning en onvergetelijke uitzichten, wachten onze appartementen <strong>{{colores23.props.children}}</strong> en <strong>{{colores27.props.children}}</strong> in Costa Calma op je.",
          Apartment_Info2: "We bieden 2 volledig ingerichte appartementen met een terras, elk geschikt voor 4 personen.",
          Apartment_Info3: "De appartementen bevinden zich in een beveiligde gemeenschap met toegang tot een zwembad en een winkel op 20 meter van de ingang.",
          Apartment_1_description: "Zoek je de perfecte plek voor een <strong>vakantie in Fuerteventura?</strong> Het appartement <strong>Colores de Fuerteventura 23</strong> in het stadje <strong>Costa Calma</strong> heeft een moderne, gezellige inrichting die uitzonderlijk comfort en ontspanning biedt. Na een grondige renovatie in 2023 biedt het appartement een <strong>hoge standaard</strong> van afwerking, ontworpen met veeleisende gasten in gedachten. Het is gelegen in een pittoresk complex met een zwembad en palmbomen.",
          Apartment_1_description_2: "Het appartement Colores de Fuerteventura 23, gelegen op de begane grond met een <strong>terras in de schaduw van exotische planten</strong>, garandeert een rustige verblijf in een intieme sfeer. Gasten kunnen genieten van het charmante interieur in boho-stijl in de <strong>pastelkleuren</strong> van het eiland. Het is ontworpen door een ontwerper en heeft een unieke vakantie- en eilandkarakter gekregen.",
          Apartment_1_description_3: "Het appartement Colores de Fuerteventura 23 is een uitstekende uitvalsbasis om de schoonheid van het eiland te verkennen. Gelegen nabij <strong>Sotavento Beach</strong>, op slechts 700 meter van het appartement, biedt het gasten snelle toegang tot een van de mooiste stranden van het eiland. In de buurt is er ook een winkelcentrum, restaurants, bars en een lokale markt waar je de exotische sfeer van Fuerteventura kunt ervaren.",
          Apartment_header_text: "Comfortabel appartement na renovatie – Jouw privéparadijs in Fuerteventura",
          Apartment_header_text_2: "Perfecte locatie – dicht bij het strand en lokale attracties",
          Apartment_reservation_header: "Boek vandaag nog je verblijf!",
          Apartment_1_reservation_1: "Als je op zoek bent naar rust en nabijheid van de natuur, is het appartement Colores de Fuerteventura 23 de perfecte keuze voor je vakantie.",
          Apartment_1_reservation_2: "Fuerteventura wacht op je – dompel jezelf onder in de unieke sfeer en geniet van een onvergetelijke vakantie op een van de mooiste Canarische Eilanden.",
          Apartment_2_description: "Zoek je de perfecte plek voor een <strong>vakantie in Fuerteventura?</strong> Het appartement <strong>Colores de Fuerteventura 27</strong> in het stadje <strong>Costa Calma</strong> heeft een moderne, gezellige inrichting die uitzonderlijk comfort en ontspanning biedt. Na een grondige renovatie in 2023 biedt het appartement een <strong>hoge standaard</strong> van afwerking, ontworpen met veeleisende gasten in gedachten. Het is gelegen in een pittoresk complex met een zwembad en palmbomen.",
          Apartment_2_description_2: "Het appartement Colores de Fuerteventura 27, gelegen op de eerste verdieping met een <strong>terras in de schaduw van exotische planten</strong>, garandeert een rustige verblijf in een intieme sfeer. Gasten kunnen genieten van het charmante interieur in boho-stijl in de <strong>pastelkleuren</strong> van het eiland. Het is ontworpen door een ontwerper en heeft een unieke vakantie- en eilandkarakter gekregen.",
          Apartment_2_description_3: "Het appartement Colores de Fuerteventura 27 is een uitstekende uitvalsbasis om de schoonheid van het eiland te verkennen. Gelegen nabij <strong>Sotavento Beach</strong>, op slechts 700 meter van het appartement, biedt het gasten snelle toegang tot een van de mooiste stranden van het eiland. In de buurt is er ook een winkelcentrum, restaurants, bars en een lokale markt waar je de exotische sfeer van Fuerteventura kunt ervaren.",
          Apartment_2_reservation_1: "Als je op zoek bent naar rust en nabijheid van de natuur, is het appartement Colores de Fuerteventura 27 de perfecte keuze voor je vakantie.",
          Apartment_2_reservation_2: "Fuerteventura wacht op je – dompel jezelf onder in de unieke sfeer en geniet van een onvergetelijke vakantie op een van de mooiste Canarische Eilanden.",          
          Costa_Calma_Info: "Een klein en rustig stadje aan de zuidwestkust van het eiland, met veel groen en palmbomen. Een prachtige met palmen omzoomde laan loopt door het midden van de stad en maakt het een uitstekende plek voor wandelingen en joggen op elk moment van de dag en bij elk weer. Costa Calma is een ideale plek voor wie rust en stilte zoekt, vooral gezinnen met kinderen vanwege de stranden, kalme wateren en golven (zwemzones met strandwachten) en liefhebbers van watersporten. Het stadje heeft 3 supermarkten, talrijke winkels en boetieks, een benzinestation, een postkantoor, apotheken en spoedeisende medische zorg. Als je niet wilt koken, zijn er veel betaalbare bars en restaurants op locatie. Costa Calma is een zeer goede uitvalsbasis om het eiland te verkennen, maar een auto is nodig – er zijn diverse autoverhuurbedrijven beschikbaar.",
          Popcorn_beach: "Popcorn beach – een unieke plek met witte stukjes koraal die lijken op popcorn.",
          Calderon_Hondo: "Calderon Hondo Vulkaan – een van de jongste vulkanen van het eiland. Het ligt nabij Lajeres. De beklimming is niet zwaar of moeilijk, omdat het pad naar de rand van de vulkaan om de krater heen loopt. Boven op de vulkaan (278 meter boven zeeniveau) is er een uitkijkplatform. De hele rondreis duurt ongeveer 2 uur.",
          Mirador_de_Guise_y_Ayose: "Mirador de Guise y Ayose en Mirador Morro Velosa – twee aantrekkelijke uitkijkpunten met prachtige uitzichten over de Betancuria-vallei en Fuerteventura – geweldige foto's gegarandeerd. Bij Mirador de Guise y Ayose staan twee grote standbeelden van de laatste Guanche heersers (15e eeuw). Talrijke eekhoorns, kenmerkend voor deze regio.",
          Antigua: "Een rustige en mooie stad in het midden van Fuerteventura met de interessante kerk van Nuestra Señora de Antigua, gelegen op een klein plein in het centrum van de stad. Een andere belangrijke attractie is het kaasproductiemuseum – Museo del Queso Majorero. Bij het parkeerterrein kun je een prachtige grote windmolen en een tuin met verschillende soorten cactussen bezoeken. Binnen leer je over de omstandigheden, productieprocessen en soorten geitenkaas op Fuerteventura.",
          Betancuria: "Betancuria – de voormalige hoofdstad van Fuerteventura, nu een klein toeristisch stadje dat de moeite waard is om te bezoeken vanwege de prachtige Iglesia Catedral de Santa María de Betancuria en talrijke bars en cafés.",
          Arco_de_las_planitas: "Een andere aanbevolen wandelroute is Arco de las Peñitas. Je kunt met de auto naar de voet van de heuvel rijden en vervolgens een route van 2 uur volgen om kalksteenformaties, een prachtige kloof en een oude, inactieve dam te zien.",
          Ajuy: "Een klein dorpje met een prachtige baai met zwart zand. Er zijn 3 zeer goede visrestaurants (diner voor 4 personen met een fles wijn kost ongeveer €100). Een pad vanaf het strand leidt naar de Piratengrotten, Cuevas de Ajuy (ongeveer 20 minuten) – een geweldig uitzichtpunt.",
          Aguas_Verdes: "Aguas Verdes is een rotsachtige kustlijn met talrijke kliffen en natuurlijke zwembaden – perfect om te zwemmen.",
          Caleta_de_Fuste: "Caleta de Fuste – een toeristische stad met een prachtig strand, een jachthaven en veel bars en restaurants. In de buurt zijn er verschillende golfbanen. Ongeveer 2 km naar het zuiden ligt het Zoutmuseum – je kunt er vanuit de stad naartoe wandelen. Een andere attractie in de omgeving is de zogenaamde Krabbenfelsen – een natuurlijk zwembad dat tijdens eb ontstaat.",
          Oasis_Park: "Voor gezinnen met kinderen en anderen is een belangrijke attractie het Oasis Park, een botanische dierentuin op ongeveer 10 km afstand – bereikbaar per auto, taxi of bus vanaf de rotonde bij de ingang van de stad. Een bezoek duurt 3-5 uur, maar er zijn veel schaduwrijke plekken. Een grote attractie voor kinderen is het voeren van giraffen, kamelen en andere dieren. Daarnaast is er een uitgebreide botanische tuin met verschillende soorten cactussen.",
          Tarajalejo: "Tarajalejo is een klein stadje op slechts een paar kilometer van Costa Calma, gelegen bij een schilderachtige baai. Een uur wandelen langs de oceaankust naar het oosten brengt je bij een prachtige grot. In het oostelijke deel van de stad, direct aan de kust, raden we het lokale restaurant La Barracca aan, dat een verscheidenheid aan vis- en zeevruchtenschotels en uitstekende huiswijn biedt.",
          La_Pared: "La Pared – een dorp aan de noordkust, waar de belangrijkste attracties de zonsondergangen zijn en de ideale omstandigheden voor surfen. In Costa Calma zijn er verschillende plaatsen met instructeurs die vervoer en uitrusting bieden. Let op – de noordelijke stranden zijn minder toegankelijk en behoorlijk gevaarlijk vanwege de golven en onderwaterstromingen. Ter plaatse raden we het uitstekende Caretta Beach La Pared-restaurant aan – geweldig eten en wijn, en als toetje prachtige uitzichten op de oceaan. Het dorp heeft een typische surferssfeer, met twee bars die geweldige drankjes aanbieden – live muziek op vrijdag.",
          Playa_de_Sotavento: "Playa de Sotavento – het beroemdste en meest gerenommeerde strand van het eiland en daarbuiten. Het is een prachtig zandstrand met kristalhelder turquoise water en zachte oceaangolven. Door de ligging en de vorm van het strand ontstaat er tijdens eb een prachtige lagune. Het strand van Sotavento begint bij Costa Calma en strekt zich uit over meer dan 20 km tot aan Morro Jable. Aan het zuidelijke uiteinde van het strand bij Costa Calma, richting het zuidwesten, raden we de fantastische bar Plamita Pepe y Antonia aan – waar heerlijke vis- en zeevruchtenschotels direct aan de oceaan worden geserveerd.",
          Pico_de_Zarza: "Pico de Zarza is het hoogste punt van Fuerteventura, met een hoogte van 807 meter boven zeeniveau. Het is een van de meer aantrekkelijke wandeltochten op het eiland. Vanaf de parkeerplaats aan de voet van de berg duurt het ongeveer 2 uur eenvoudige wandelingen om de top te bereiken (ongeveer 1 uur afdalen). Onderweg kom je talrijke eekhoorns en geiten tegen. Zorg ervoor dat je water en een jas meeneemt, vooral bij winderig weer. De top biedt een prachtig uitzicht op de westpunt van het eiland, inclusief het strand van Morro Jable en Cofete, samen met de Winter Villa.",
          Cofete: "Cofete – een prachtig afgelegen strand dat bereikbaar is vanuit Morro Jable met de auto via een onverharde grindweg (opmerking – huurautoverzekering dekt doorgaans dit soort reizen niet). Onderweg heb je de kans om de Winter Villa te bezoeken, maar een afspraak is noodzakelijk. Het is de moeite waard om een kleine omweg te maken naar het meest westelijke punt van het eiland om de vuurtoren van Punta de Jandia te zien.",
          Airbnb_reservation: "Boek op Airbnb",
          Host: "De beheerder van de appartementen is Robert Superhost Airbnb",
          Fuerteventura_description: "<strong>{{island.props.children}}</strong>, als een van de oudste Canarische Eilanden, biedt veel attracties.<br /><br />Bezoek <strong>{{park.props.children}}</strong>, waar je uitgestrekte duinen en pittoreske landschappen kunt bewonderen, of <strong>{{oasis.props.children}}</strong> – een unieke dierentuin en botanische tuin.",
          Climate: "Klimaat",
          Fuerteventura: "Fuerteventura",
          Place_select: "Selecteer een plaats op de kaart om meer te leren.",
          Privacy_respect: "Wij respecteren uw privacy.",
          Cookies_consent: "We willen cookies gebruiken om u een handige ervaring op het <strong>{{webpage.props.children}}</strong> portaal te bieden en toegang te geven tot de laatste verbeteringen die we creëren via voortdurende analyses. Als u akkoord gaat met het gebruik van alle cookies uitsluitend voor statistische doeleinden, klik dan op 'Accepteren.' U kunt uw toestemming op elk moment intrekken door uw cookie-instellingen te wijzigen, ze te verwijderen of uw browserinstellingen aan te passen.",
          Accept: "Accepteren",
          Close: "Sluiten",
          Climate_title: "Het Klimaat van het Paradijseiland Fuerteventura",
          Climate_description_1: "Fuerteventura, een van de oudste eilanden van de <strong>Canarische Eilanden</strong> archipel, staat bekend om zijn unieke klimaat en spectaculaire landschappen. Dankzij de ligging in de Atlantische Oceaan, op slechts 100 kilometer van de Afrikaanse kust, geniet het eiland van een mild semi-woestijnklimaat met een subtropisch karakter. Het is een plek waar natuurlijke diversiteit samenkomt met uitzonderlijke weersomstandigheden, wat het hele jaar door toeristen van over de hele wereld aantrekt.",
          Climate_description_2: "Op <strong>Fuerteventura</strong> zijn er praktisch geen grote temperatuurverschillen, met gemiddelde waarden variërend van 18°C in de winter tot 30°C in de zomer. Het eiland staat echter bekend om zijn handelswinden, die de verkoelende invloed van de oceaan brengen en helpen het klimaat aangenaam mild te houden, zelfs tijdens de zomermaanden. Deze winden, die regelmatig vanuit het noordoosten waaien, hebben <strong>Fuerteventura</strong> de bijnaam 'Eiland van de Wind' gegeven. Ze zijn vooral gunstig voor watersportliefhebbers, zoals windsurfen en kitesurfen, waardoor Fuerteventura een van de beste locaties voor deze activiteiten is.",
          Climate_description_3: "Hieronder volgt een gedetailleerde beschrijving van het klimaat en de weersomstandigheden in de stad <strong>Costa Calma</strong>, verdeeld per seizoen, die zal helpen bij het plannen van uw verblijf volgens uw weer voorkeuren en activiteiten.",
          Spring: "Lente (maart - mei)",
          Summer: "Zomer (juni - augustus)",
          Autumn: "Herfst (september - november)",
          Winter: "Winter (december - februari)",
          Spring_text_1: "<strong>Temperatuur:</strong> In de lente variëren de temperaturen van 20°C tot 25°C overdag, terwijl de nachten iets koeler zijn, rond de 15°C - 18°C.",
          Spring_text_2: "<strong>Neerslag:</strong> Neerslag is in de lente zeer zeldzaam, wat het landschap droog en semi-woestijnachtig houdt. Af en toe komen er lichte buien voor aan het begin van de lente.",
          Spring_text_3: "<strong>Wind:</strong> Handelswinden beginnen in deze periode te intensiveren, vooral in mei, waardoor het eiland een paradijs wordt voor surfers, windsurfers en kitesurfers.",
          Spring_text_4: "<strong>Vochtigheid:</strong> De vochtigheid is relatief laag, wat bijdraagt aan het comfort, zelfs bij hogere temperaturen.",
          Spring_text_5: "<strong>Zee:</strong> De watertemperatuur in de lente schommelt rond de 18-20°C, wat voor sommigen misschien wat koel is, maar nog steeds zwembaar.",
          Summer_text_1: "<strong>Temperatuur:</strong> In de zomer variëren de temperaturen tussen 25°C en 30°C, hoewel ze 30°C kunnen overschrijden, vooral tijdens de instroom van warme lucht uit de Sahara (het calima-fenomeen). De nachttemperaturen zijn aangenaam, variërend van 20°C tot 24°C.",
          Summer_text_2: "<strong>Neerslag:</strong> De zomer op Fuerteventura is praktisch regenloos, en neerslag in deze periode is een ware zeldzaamheid.",
          Summer_text_3: "<strong>Wind:</strong> Handelswinden waaien het sterkst in de zomer, waardoor het eiland afkoelt en de hitte veel draaglijker wordt.",
          Summer_text_4: "<strong>Vochtigheid:</strong> In de zomer is de vochtigheid laag, wat het gevoel van benauwdheid vermindert, ondanks de hoge temperaturen.",
          Summer_text_5: "<strong>Zee:</strong> De oceaantemperatuur stijgt naar 22-23°C, wat ideaal is voor zwemmen en watersport.",
          Autumn_text_1: "<strong>Temperatuur:</strong> In de herfst zijn de temperaturen zeer mild, met dagwaarden van 24°C tot 28°C en nachttemperaturen tussen 19°C en 22°C.",
          Autumn_text_2: "<strong>Neerslag:</strong> De herfst is de enige periode waarin meer merkbare neerslag kan voorkomen, hoewel het nog steeds zeldzaam is en niet lang aanhoudt.",
          Autumn_text_3: "<strong>Wind:</strong> Herfstwinden verzwakken in vergelijking met de zomer, wat bijdraagt aan wat kalmere omstandigheden, ideaal voor toeristen die de voorkeur geven aan minder winderig weer.",
          Autumn_text_4: "<strong>Vochtigheid:</strong> Er is een merkbare toename van de vochtigheid, maar deze blijft gematigd.",
          Autumn_text_5: "<strong>Zee:</strong> De watertemperatuur in de herfst is een aangename 22-24°C, wat watersportliefhebbers en zwemmers aantrekt.",
          Winter_text_1: "<strong>Temperatuur:</strong> De winter in Fuerteventura is uitzonderlijk mild – de dagtemperaturen bereiken 19°C tot 22°C, terwijl de nachttemperaturen dalen tot 15°C tot 18°C.",
          Winter_text_2: "<strong>Neerslag:</strong> December en januari zijn maanden waarin er iets meer regen valt, hoewel het nog steeds een klein bedrag is.",
          Winter_text_3: "<strong>Wind:</strong> In de winter is de wind aanzienlijk zwakker dan in de zomer, waardoor het eiland rustiger en geschikter is voor toeristen die de voorkeur geven aan mildere weersomstandigheden.",
          Winter_text_4: "<strong>Vochtigheid:</strong> De vochtigheid neemt toe tot een gemiddeld niveau, waardoor het klimaat iets vochtiger is dan in de zomer.",
          Winter_text_5: "<strong>Zee:</strong> De oceaantemperatuur daalt tot rond de 18-20°C, maar het is nog steeds mogelijk om aan watersport en zwemmen te doen, hoewel het voor sommigen koel kan zijn.",
          Commuting_info: "Op deze pagina vindt u gedetailleerde informatie over beschikbare transportmogelijkheden om onze appartementen vanaf <strong>Puerto del Rosario</strong> luchthaven te bereiken. Hieronder staat ook een luchthavend kaart om u te helpen met navigeren en het plannen van uw reis.",
          Commuting_tiadhe_1: "U kunt de appartementen bereiken met Tiadhe stadsbussen, <strong>lijn 10</strong>. Tiadhe bussen zijn gemarkeerd in <strong>(wit-turquoise of marineblauw)</strong>. De bushalte bevindt zich voor de hoofdingang. Het schema wordt op een metalen paal geplaatst.",
          Commuting_tiadhe_2: "U kunt een link naar de Tiadhe-website en het schema voor lijn 10 vinden ",
          Commuting_tiadhe_3: "hier",
          Commuting_tiadhe_4: "De ticketprijs zal ongeveer zijn",
          Commuting_tiadhe_5: "10 € / persoon",
          Commuting_shuttle_1: "Na het verlaten van de hoofdhal van de luchthaven, gaat u rechts langs de weg, en blijft u rechtdoor gaan voorbij de taxi stand. De shuttlebusstop zal voor u zijn - zoek naar <strong>oranje bussen</strong>.",
          Commuting_shuttle_2: "Op de website van de vervoerder",
          Commuting_shuttle_3: "selecteert u gewoon het appartementadres, vluchtnummer en geplande aankomsttijd. Dan, tot 72 uur voor aankomst, ontvangt u een e-mail met de vertrektijd van de shuttle. <strong>Deze bus wacht op zijn passagiers</strong>.",
          Commuting_shuttle_4: "De ticketprijs zal ongeveer zijn",
          Commuting_shuttle_5: "17 € / persoon",
          Commuting_taxi_1: "Na het verlaten van de hoofdhal van de luchthaven, gaat u rechts langs de weg, de taxistand is gemarkeerd met een Taxi-bord. Alleen erkende <strong>witte taxi's</strong> zijn hier beschikbaar. Helaas zijn Uber/Bolt-apps niet beschikbaar op de luchthaven.",
          Commuting_taxi_2: "De prijs hangt af van de taximeter.",
          Commuting_taxi_3: "De kosten van de rit zullen ongeveer zijn",
          Commuting_hire_1a: "In de hoofdhal van de luchthaven zijn er tal van autoverhuurbedrijven. Na het kiezen van een auto, wordt u naar het parkeergebied geleid dat is gemarkeerd met de letter ",
          Commuting_hire_1b: " op de kaart hierboven, waar uw voertuig zich zal bevinden.",
          Commuting_hire_2: "<strong>Maak gedetailleerde foto's van de staat van de auto binnen de eerste minuten van de huur.</strong>",
          Commuting_hire_3: "De huurprijs begint bij",
          Commuting_hire_4: "20-30 € / dag",
          Commuting_hire_5: "Het bedrag hangt af van de leeftijd van de huurder en de duur van de huurperiode.",
          Commuting: "Vervoer",

          },
      }
    },
  });

export default i18n;